<template>
   {{ manager }}
</template>

<script>
import store from "../../store/index";
export default{
    data()
    {
        return {
            manager:{},
            hotelid:"",
        }
    },
  mounted(){
    this.manager = store.state.manager;
   
  }


}
</script>