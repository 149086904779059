<template>
  <div class="main">

    <div v-if="showSuccessMessage" class="success-message bg-green-500 text-white  rounded-md shadow-md animate-fade-out mb-10">
      Hotel registered successfully!
    </div>

    <router-view></router-view>
    
  </div>
</template>
<script>
import axios from 'axios';
export default{

 data() {
    return {
      showSuccessMessage: false,
      showSuccessMessageerror:false,
      formData: {
        name: '',
        totalroom: '',
        price: '',
        hmoto: '',
        hadress: '',
        hAdress2: '',
        hmainPhoneNumber: '',
        latitude: '',
        longitude: '',
        HmainImagePath: null,
        hcity: '',
        hstate: '',
        htype: '',
      },
    };
  },
  props:{
     manager:{
      type:Array,
     }
  },
  methods: {
    searchinghotel()
    {
      console.log('yOUR WELCOME HERE');
    },
    handleImageUpload(event) {
      this.formData.HmainImagePath = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      for (const key in this.formData) {
        formData.append(key, this.formData[key]);
      }

      

      axios.post('https://api.dormi.co.tz/api/addingHotel', formData)
        .then(response => {
          console.log('Hotel registered successfully:', response.data);
          this.showSuccessMessage = true; // Show the success message
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide the success message after 2 seconds
          }, 3000);
          this.formData.HmainImagePath = "";
          this.formData.hAdress2='';
          this.formData.htype="";
          this.formData.hadress="";
          this.formData.hmainPhoneNumber="";
          this.formData.latitude="";
          this.formData.longitude="";
          this.formData.name="";
          this.formData.price="";
          this.formData.totalroom=""
        })
        .catch(error => {
         this.showSuccessMessageerror = true;
         setTimeout(() => {
            this.showSuccessMessageerror = false; // Hide the success message after 2 seconds
          }, 3000);
          console.error('Error registering hotel:', error);
        });
    },
  },
   
  
};


</script>


  
 
 