<template>
  <div>
    <Navbar />

    <div class="container mx-auto p-4 sm:p-8">
      <div class="bg-white rounded-lg shadow-md p-6">
        <h1 class="text-4xl font-bold mb-8 text-center text-purple-600">Dormi Reservation Policy</h1>

        <div class="text-base">
          <p class="mb-4">
            Welcome to Dormi, your go-to platform for hotel, guest, and restaurant reservations. We are delighted to
            have you as our valued customer. To ensure a seamless and enjoyable experience for all our users, we have
            established the following reservation policies:
          </p>

          <ol class="list-decimal ml-8 mb-6">
            <li class="mb-4">Hotel and Guest Reservations:
              <ul class="list-disc ml-4 mt-2">
                <li>All hotel and guest reservations are subject to availability at the time of booking.</li>
                <li>Users can view the prices for each hotel and guest accommodation during the reservation process.
                  The prices may vary based on room types, seasonal demand, and special promotions.</li>
              </ul>
            </li>
            <li class="mb-4">Restaurant Reservations:
              <ul class="list-disc ml-4 mt-2">
                <li>Restaurant reservations can be made for specific dates and times, subject to availability.</li>
                <li>The availability of restaurant tables may vary based on peak hours and special events.</li>
                <li>Guests are encouraged to make restaurant reservations in advance to secure their preferred dining
                  time.</li>
              </ul>
            </li>
            <li class="mb-4">Booking Confirmation:
              <ul class="list-disc ml-4 mt-2">
                <li>Once a reservation is made, users will receive a confirmation email containing reservation
                  details, including the hotel/guest name, check-in/check-out dates (for hotel reservations), restaurant
                  name, reservation date/time (for restaurant reservations), and total price.</li>
              </ul>
            </li>
            <!-- Add more policy points here -->
          </ol>

          <p class="mb-6">By using the Dormi platform and making reservations through our services, you agree to comply
            with the policies outlined above. We are committed to providing you with excellent service and a smooth
            reservation process, and we appreciate your cooperation.</p>

          <p class="mb-2 font-semibold">Thank you for choosing Dormi for your hotel, guest, and restaurant reservations.
            We look forward to serving you!</p>

          <p class="text-center text-purple-500 text-lg mt-8">Sincerely,<br>chrisphine<br>Founder, Dormi</p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/Navbar-component.vue";
import Footer from "../components/Footer-Component.vue";
export default{

    components:{
        Navbar,
        Footer
    }

}
</script>
