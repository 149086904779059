<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-400 to-purple-600">
      <!-- Replace the text with an SVG image -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-24 w-24 text-white mb-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <!-- Add your SVG image code here (replace this with your SVG code) -->
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M5 13l4 4L19 7"
        />
      </svg>
      <p class="text-white text-2xl font-medium mb-6">
        Page not found
      </p>
      <button
        @click="goBack"
        class="text-white bg-purple-600 hover:bg-purple-700 py-2 px-4 rounded-md shadow-md transition-all duration-300 ease-in-out"
      >
        Go Back
      </button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  <style>
  /* Add animation to fade-in the component */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Apply the fade-in animation to the component */
  .fade-in {
    animation: fadeIn 1s ease-out;
  }
  </style>
  