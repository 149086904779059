<template>
    <Navbar></Navbar>
           
    <HdetailHeader :hotelData="hotel.hotel"/>
    <Gallary       :hotelData="hotel.hotel" :hotels="hotel"></Gallary>
    <Hotelservice  :hotelData="hotel.hotel" :expaire="hotel.expaire"/>
    <Abouthotel    :hotelData="ouserservice" />
  
    <template v-if="!hotel.hotel">
      <div class="loader">Loading...</div>
    </template>

    <!-- Render the HotelMap component once the hotel.hotel data is entered -->
    <template v-else>
       <HotelMap :hotelData="hotel.hotel" class="z-0 mb-6"/>
    </template>

    
    <Hfooter></Hfooter>

  </template>
  
  <script>
  import Navbar from "../components/Navbar-component.vue";
  import Hfooter from "../components/Footer-Component.vue";
  import Hotelservice from "../components/Hotel-services.vue";
  import HdetailHeader from "../components/HotelDetai-component.vue";
  import Gallary from "../components/photogarallary-component.vue";
  import Abouthotel from "../components/Aboutplace-Component.vue";
  import HotelMap from "../components/Hotel-map.vue";
  import api from "../api/api";
  export default {
    props: {
      id: {
        type: Number,
        required: true,
        validator: (value) => Number.isInteger(value),
      },
    },
  
    data() {
      return {
        hotel: [],
        ouserservice:[],
        loading: true,
      };
    },
    async created() {
      try {
        const response    = await api.get(`alldata/${this.id}`); // Include the 'id' prop in the API request
        this.hotel        = response.data; // Assign the retrieved data to 'hotel'
        this.loading      = false;
        const response2   = await api.get(`hotelservice/${this.id}`); // Make the second API request
        this.ouserservice = response2.data;
        this.loading      = false;
        //setting the id into the vuex for future uses
        console.log(this.id);

        
      } catch (error) {
        console.error('Error fetching data:', error);
        this.loading = false;
      }
    },
    components: {
      Navbar,
      Hfooter,
      HdetailHeader,
      Hotelservice,
      Gallary,
      Abouthotel,
      HotelMap,
    },
  };
  </script>
  
  <style scoped>
  .info {
    margin-left: 120px;
    margin-right: 120px;
    margin-top: 10px;
  }
  </style>
  