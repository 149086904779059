<template>
  {{ reference_number }}
<div class="flex justify-center items-center h-screen">
  <div class="p-4 rounded-lg shadow-md max-w-sm w-full relative bg-white">
    
    <h2 class="text-xl font-semibold mb-2">Booking Details</h2>
    <div class="bg-blue-500 text-white p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300">

  <div class="bg-blue-500 text-white p-4 rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300">
  <p class="text-2xl font-bold">
    <i class="fas fa-money-bill-wave"></i> {{ bookingDetail.totalprice.toLocaleString() }} Tsh
  </p>
  <p class="text-lg">
    <i class="fas fa-moon"></i> {{ bookingDetail.days }} Night
  </p>


</div>
  
</div>
   
    <div class="grid grid-cols-2 gap-4">
  <div class="col-span-2 sm:col-span-1 flex items-center">
    <i class="fas fa-phone text-gray-400 mr-2"></i>
    <p class="text-gray-600">{{ bookingDetail.payment.phone_number }}</p><br>
  </div>
  <div class="col-span-2 sm:col-span-1 flex items-center mt-9">
    <i class="fas fa-file-alt text-gray-400"></i>
    <p class="font-bold mt-1 mx-1"> Reference <span class="text text-orange-400">{{ bookingDetail.payment.reference_number }}</span></p><br>

   
  </div>
  <div class="col-span-2 sm:col-span-1 flex items-center">
    <i class="fas fa-credit-card text-gray-400 mr-2"></i>
    <p class="text-gray-600"> {{ bookingDetail.payment.paymentmethod }}</p>
  </div>
</div>
<p class="text-green-500 mt-4"> <i class="fa fa-check-circle text-3xl"></i>   Successfully booked!</p>
<p class="text-gray-700 mt-4 animate__animated animate__fadeInUp">

  <span class="font-bold">Note:</span> Malipo yamekamilika kupitia namba {{ bookingDetail.payment.phone_number}}! Hifadhi kumbukumbu number "{{ bookingDetail.payment.reference_number }}"
</p>
<button class="bg-blue-500 shadow-lg text-white font-bold py-2 px-4 rounded mt-4 transform hover:scale-105 transition-transform duration-300 ease-in-out" @click="downloadInvoice">
  <i class="fas fa-file-download"></i> Download Invoice
</button>
  </div>
</div>
</template>



<script>
import { mapActions } from 'vuex';
import router from '@/Router';
export default {

  

  props: {
    bookingDetail: {
      type: Object, // You can specify the expected data type
      required: true, // You can make the prop required if necessary
    },
  },

  computed: {
    // Computed property that returns the total price of the booking
    totalPrice() {
      return this.bookingDetail.reference_number;
    },
  },

methods: {
  ...mapActions(['setdata']),
  downloadInvoice() {
  router.push({ name: 'invoice'});
  },
},

};
</script>