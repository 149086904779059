<template>
  <div>
    <div v-if="!ispresent" class="container w-full h-10 shadow-lg bg-gray-400 rounded-lg mb-2 flex items-center justify-center text-white font-bold">
     This Hosting is under control by Manager
</div>
    <div class="container md:flex-row gap-1">
      <div
        v-if="hotelData.hotel"
        class="container shadow-lg rounded-lg bg-white w-full  p-4 mb-4 md:mb-0"
      >
        <div class="flex items-center justify-center">
          <div
            class="bg-white rounded-lg shadow-md w-full md:max-w-md lg:max-w-lg"
          >
            <img
              :src="hotelData.hotel.HmainIMagePath"
              alt="Hotel Profile Picture"
              class="w-full h-auto mb-4 rounded-lg shrink-0"
            />
            <h2 class="text-xl font-semibold mb-2">
              {{ hotelData.hotel.name }}
            </h2>
            <p class="text-gray-600 mb-2">
              {{ hotelData.hotel.hAdress }} {{ hotelData.hotel.hcity }},
              {{ hotelData.hotel.hstate }}
            </p>
            <transition name="fade" appear>
              <p class="text-gray-600 mb-2 animate-fade">
                Total Rooms: {{ hotelData.hotel.totalroom }}
              </p>
            </transition>
            <p class="text-gray-600 mb-2">
              Phone: {{ hotelData.hotel.hmainPhonenumber }}
            </p>

            <button
              type="button"
              class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              @click="addingservicesesdata"
            >
              Adding service
            </button>

            <button
              v-if="ispresent"
              type="button"
              class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              @click="showmanagermodel"
            >
              adding manager
            </button>

            <button
              type="button"
              class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
              @click="addingRoom"
            >
              update Hosting info
            </button>
          </div>
        </div>
        <div
          class="container mx-auto bg-white shadow-lg mt-4 rounded-lg p-4 md:p-6 lg:p-8"
          v-if="!ismaanger"
        >
          <p class="text-black text-bold">Manager Detail</p>
          <ul>
            <li class="mb-2 md:mb-4 lg:mb-6 flex items-center">
              <svg
                class="w-5 h-5 mr-2 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              <span class="font-bold">{{ usermanager.lname }}</span>
            </li>
            <li class="mb-2 md:mb-4 lg:mb-6 flex items-center">
              <svg
                class="w-5 h-5 mr-2 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
              <span class="font-bold">{{ usermanager.address }}</span>
            </li>
          </ul>
        </div>
        <div v-else class="text-center mt-4">
    <svg class="w-16 h-16 mx-auto animate-bounce text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l-7-7 7-7 7 7-7 7z"></path>
    </svg>
    <p class="text-lg md:text-xl lg:text-2xl font-semibold text-gray-600">No Manager is assigned to this hotel</p>
</div>

      </div>
      <div class="w-full md:w-1/2 bg-white" v-else>
        <p>
          There is NO picture of the Hosting GIven Go to the dashboard and
          adding the Picture to the hsoting
        </p>
      </div>
    </div>
  </div>

  <!-- {{ hotelData.hotelservice }} -->
  <div
    class="absolute inset-0 flex items-center justify-center overlay"
    v-if="addingservices"
  >
    <div class="w-full max-w-md p-4">
      <div
        class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
        role="alert"
        v-if="uploaded"
      >
        <div class="flex">
          <div class="py-1">
            <svg
              class="fill-current h-6 w-6 text-teal-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="text-sm">Sucessifully uploading the hosting service</p>
          </div>
        </div>
      </div>

      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
        v-if="errordataservicedaa"
      >
        <strong class="font-bold">Sorry</strong>
        <span class="block sm:inline"
          >Please make sure inserting services correctly</span
        >
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
            />
          </svg>
        </span>
      </div>
      <form
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        v-if="hotelData.hotel"
      >
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="hotelId"
          >
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="hotelId"
            v-model="index"
            type="text"
            hidden
            placeholder="Enter Hotel ID"
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="textInput"
          >
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="textInput"
            type="text"
            placeholder="Enter service Name"
            v-model="servicename"
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="dropdown"
          >
            Select service
          </label>
          <select
            class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            id="dropdown"
            v-if="hotelData.hotelservice"
            v-model="icon"
          >
            <option
              v-for="service in hotelData.hotelservice"
              :key="service.id"
              :value="service.icon"
            >
              {{ service.sname }}
            </option>
          </select>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            @click="submithoteldata"
          >
            Adding services
          </button>

          <button
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            @click="closeForm"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>

  <div
    class="absolute inset-0 flex items-center justify-center overlay"
    v-if="addinghotelinformation"
  >
    <div class="w-full max-w-md p-4">
      <div
        class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
        role="alert"
        v-if="messagesend"
      >
        <div class="flex">
          <div class="py-1">
            <svg
              class="fill-current h-6 w-6 text-teal-500 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="text-sm">
              Now the service is updated suceesifully sucessifully
            </p>
          </div>
        </div>
      </div>

      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
        v-if="itfail"
      >
        <strong class="font-bold">Sorry</strong>
        <span class="block sm:inline"
          >Now the hosting sercice failed to updated!!</span
        >
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg
            class="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
            />
          </svg>
        </span>
      </div>
      <form
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        v-if="hotelData.hotel"
      >
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="hotelId"
          >
          </label>
          <input
            class="shadow welcome to our pannel we provide service for all please make shure -none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="hotelId"
            v-model="index"
            type="text"
            hidden
            placeholder="Enter Hotel ID"
          />
        </div>
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="textInput"
          >
          </label>

          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            v-model="hotelInfo"
            class="border px-4 py-2 rounded-lg w-full"
          ></textarea>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center space-x-2"
            type="button"
            @click="submithotelinfo"
          >
            <i class="fas fa-paper-plane"></i>
            <!-- Font Awesome icon -->
          </button>

          <button
            class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            @click="closeForm"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- adding the manager -->

  <div
    class="addingmanager mt-28 overlay absolute inset-0 flex items-center justify-center"
    v-if="addingmanagerhere"
  >
    <form
      class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      v-if="hotelData.hotel"
    >
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="hotelId">
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hotelId"
          v-model="fname"
          type="text"
          placeholder="Fname"
        />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="hotelId">
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hotelId"
          v-model="lname"
          type="Lname"
          placeholder="Lname"
        />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="hotelId">
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hotelId"
          v-model="email"
          type="Email"
          placeholder="Email"
        />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="hotelId">
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hotelId"
          v-model="Adress"
          type="lOcation & address"
          placeholder="Address"
        />
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="hotelId">
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hotelId"
          v-model="index"
          type="text"
          hidden
          placeholder="Enter Hotel ID"
        />
      </div>
      <div class="flex items-center justify-between">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          @click="addingmanager"
        >
          submit
        </button>

        <button
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          @click="closeFormhere"
        >
          Close
        </button>
      </div>
    </form>
  </div>


  <div class="container bg-shadow rounded-lg w-full  mt-6 bg-slate-300 pt-3 ">
    <swiper
  v-if="hotelData.photos && hotelData.photos.length > 0"
    :effect="'cube'"
    :grabCursor="true"
    :cubeEffect="{
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    }"
    :pagination="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide  v-for="photo in hotelData.photos"
                :key="photo.id"
      >
      
      <img
      :src="photo.photo_url" /></swiper-slide
    >
  </swiper>

  </div>
  
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';

  import { EffectCube, Pagination } from 'swiper/modules';
  import 'swiper/css/effect-cube';
  import 'swiper/css/pagination';
import axios from "axios";
export default {
  components: {
      Swiper,
      SwiperSlide,
    },
  data() {
    return {
      hotelData: [],

      fname: "",
      lname: "",
      Adress: "",
      email: "",
      manager: {},
      hotelIdInput: "",
      messagesend: false,
      servicename: "",
      addingservices: false,
      icon: "",
      itfail: false,
      hotelInfo: "",
      formservice: {},
      hotelId: "",
      addinghotelnumber: "89",
      formdata: {},
      ourservices: {},
      addingroom: false,
      addinghotelinformation: false,
      addingmanagerhere: false,
      usermanager: {},
      ispresent: true,
      // Initialize the propertyc
    };
  },
  mounted() {
    this.fetchData();
    this.findmenager();
  },
  methods: {
    async findmenager() {
      const manager = {
        hotelid: this.hotelId,
      };
      const response = await axios.post(
        "https://api.dormi.co.tz/api/managerfind",
        manager
      );
      console.log(response.data);
      if (response.data == 1) {
        this.ispresent = true;
      } else {
        this.usermanager = response.data;
        this.ispresent = false;
      }
    },
    showmanagermodel() {
      this.addingmanagerhere = true;
    },
    closeFormhere() {
      this.addingmanagerhere = false;
    },
    addingmanager() {
      this.manager = {
        hotelid: this.hotelId,
        fname: this.fname,
        lname: this.lname,
        address: this.Adress,
        email: this.email,
      };
      this.InsertManager(this.manager);
      this.addingmanagerhere = true;
    },
    ...mapActions(["InsertpictureId"]),
    ...mapActions(["InsertManager"]),
    ...mapActions(["ServiceOfhotel"]),
    ...mapActions(["addinghotelroom"]),

    ...mapActions(["sendingServiceInfo"]),

    submithotelinfo() {
      this.formservice = {
        hotelid: this.hotelId,
        bestofall: this.hotelInfo,
      };

      if (this.hotelInfo == "") {
        this.itfail = true;
      } else {
        this.sendingServiceInfo(this.formservice);
        this.hotelInfo = "";
        this.messagesend = true;
        if (!this.messagesend) {
          this.itfail = true;
        }
      }
    },

    deleteHotelOhoto(hotelid) {
      const confimation = confirm("You want to delete this photo");

      console.log(confimation);
      console.log(hotelid);
      if (confimation) {
        this.InsertpictureId(hotelid)
          .then(() => {
            this.fetchData(); // Refresh data after successful deletion
          })
          .catch((error) => {
            console.error("Error deleting photo:", error);
          });
      }
    },
    submithoternumber() {
      this.formdata = {
        hotelid: this.hotelId,
        icon: this.icon,
        sname: this.servicename,
      };
    },

    async fetchData() {
      try {
        const response = await fetch(
          `https://api.dormi.co.tz/api/alldata/${this.hotelId}`
        );
        const jsonData = await response.json();
        this.hotelData = jsonData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
   

    addingRoom() {
      this.addinghotelinformation = true;
    },
    addingservicesesdata() {
      this.addingservices = true;
    },
    closeForm() {
      this.addingservices = false;
      this.addinghotelinformation = false;
    },
    submithoteldata() {
      this.formdata = {
        hotelid: this.hotelId,
        icon: this.icon,
        sname: this.servicename,
      };

      this.ServiceOfhotel(this.formdata);
    },
    addingserviceses() {
      this.addingservices = true;
    },
  },

  created() {
    this.hotelId = this.$route.params.index; // Access the hotelId parameter from the route
    this.fetchData();
  },
  computed: {
    ...mapGetters(["getServiceupoaded"]),
    ...mapGetters(["errordata"]),

    errordataservicedaa() {
      return this.$store.getters.errordata;
    },

    uploaded() {
      return this.$store.getters.getServiceupoaded;
    },

    index() {
      return this.$route.params.index;
    },
  },
  setup() {
      return {
        modules: [EffectCube, Pagination],
      };
    },
};
</script>

<style scoped>
.swiper {
  width: 300px;
  height: 300px;

}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

</style>



