<template>
  <div class="bg-white px-auto w-full md:px-16 py-3 mt-20">
    <div v-if="isLoading" class="flex items-center justify-center h-48">
      <div class="loader"></div>
    </div>
    <div v-else class="container mx-auto">
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="information mt-4">
          <p class="hname mx-10 sm:justify-center">
            {{ hotelData.name }}
          </p>
          <div class="flex flex-row gap-3 mx-10">
            <div class="shrink-0"></div>
            <div class="">
              <span> <i class="fas fa-star">4.7 reviews</i></span>
            </div>
            <div>
              <span>
                <i class="fas fa-map-marker-alt"></i>
                {{ hotelData.hadress }}
              </span>
            </div>
          </div>
        </div>
        <div class="hidden md:inline mt-5 mx-10">
          <div class="flex flex-row gap-3">
            <span>
              <i class="fas fa-save"></i>
            </span>

            <div class="relative inline-block">
              <span
                id="shareButton"
                @click="share"
                class="text-gray-600 cursor-pointer"
              >
                <i class="fas fa-share-alt"></i>
              </span>
              <div
                id="popover"
                class="absolute bg-gray-900 text-white px-3 py-2 rounded-md text-sm opacity-0 invisible transition-all duration-300"
              >
                share
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="shareFacebook"
      class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75"
    >
      <div class="bg-white p-4 rounded-lg shadow-lg w-full max-w-md">
        <button
          class="close text-red-500 hover:text-gray-700 transition duration-300"
        >
          <i class="fas fa-times" @click="share"></i>
        </button>
        <h3 class="text-lg font-medium mb-4">Share on social media:</h3>
        <div class="flex flex-col sm:flex-row gap-4">
          <span
            class="flex items-center justify-center bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 transition duration-300"
            @click="Facebook(hotelData)"
          >
            <i class="fab fa-facebook-f mr-2"></i> Facebook
          </span>
          <span
            class="flex items-center justify-center bg-purple-500 text-white rounded-lg px-4 py-2 hover:bg-purple-600 transition duration-300"
            @click="shareInstagram(hotelData)"
          >
            <i class="fab fa-instagram mr-2"></i> Instagram
          </span>
          <span
            class="flex items-center justify-center bg-blue-400 text-white rounded-lg px-4 py-2 hover:bg-blue-500 transition duration-300"
            @click="shareWhatsapp(hotelData)"
          >
            <i class="fab fa-twitter mr-2"></i> Whatsapp
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shareFacebook: false,
      isLoading: true,
    };
  },
  props: {
    hotelData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // Simulating loading data
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    share() {
      this.shareFacebook = !this.shareFacebook;
    },
    shareWhatsapp(hotel) {
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        "Check out this hotel: " + hotel.name + ", " + hotel.hadress
      )}`;
      window.open(whatsappShareUrl, "_blank");
    },
    Facebook(hotel) {
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        window.location.href
      )}&quote=${encodeURIComponent(
        "Check out this hotel: " + hotel.name + ", " + hotel.hadress
      )}`;
      window.open(facebookShareUrl, "_blank");
    },
    shareInstagram(hotel) {
      const instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
        window.location.href
      )}&title=${encodeURIComponent(
        "Check out this hotel: " + hotel.name + ", " + hotel.hadress
      )}`;
      window.open(instagramShareUrl, "_blank");
    },
  },
};
</script>

<style scoped>
/* Add any custom CSS styles here if needed */
.hname {
  font-size: 20px;
}
.close {
  position: absolute;
  top: 80px;
  right: 23px;
}
#shareButton:hover + #popover {
  opacity: 1;
  visibility: visible;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
