<template>
  <div class="hotelservices mb-1 mx-auto">
    <div
      class="information flex flex-col gap-4 px-4 sm:gap-8 sm:px-10 md:flex-row"
    >
      <!-- Service Icons -->
      <div class="service w-full md:w-1/2">
        <div class="ourinfo flex flex-col items-center md:items-start">
          <h3 class="text-xl text-start font-bold">What this place offers</h3>
          <div class="flex flex-col flex-wrap gap-2 justify-start">
            <div
              v-for="(service, index) in hotelData.hotelservices"
              :key="index"
              class="w-1/2 text-start"
            >
              <div v-if="index < 4">
                <i
                  :class="[service.icon, 'fa-1x', 'mr-2']"
                  aria-hidden="true"
                ></i>
                <span>{{ service.sname }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal toggle -->
        <transition name="fade" mode="out-in"> </transition>

        <div
          class="border-solid border-black border-2 w-40 h-10 pt-1 cursor-pointer text-center"
          @click="showservicemodeldata"
        >
          show all Services
        </div>
      </div>

      <!-- Main modal -->
      <transition name="fade" mode="out-in">
        <div
          class="fixed content inset-0 flex justify-center items-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto"
          v-if="showservicemodel"
        >
          <div class="relative w-full max-w-2xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow">
              <!-- Modal header -->
              <div
                class="flex items-start justify-between p-4 border-b rounded-t shadow-sm"
              >
                <h3 class="text-xl font-semibold text-black dark:text-white">
                  Terms of Service
                </h3>
                <button
                  type="button"
                  class="text-black bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                    @click="showservicemodeldata"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span class="sr-only" @click="showservicemodeldata"
                    >Close modal</span
                  >
                </button>
              </div>
              <!-- Modal body -->
              <div class="p-6 space-y-6 shadow-xl">
                <div class="flex flex-col flex-wrap gap-2 justify-start">
                  <h1>What this place offers</h1>
                  <br />

                  <div
                    v-for="(service, index) in hotelData.hotelservices"
                    :key="index"
                    class="w-1/2 text-start"
                  >
                    <i
                      :class="[service.icon, 'fa-1x', 'mr-2']"
                      aria-hidden="true"
                    ></i>
                    <span>{{ service.sname }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <!-- About the Place -->
      <div class="service w-full md:w-1/2">
        <p class="mb-3 font-bold">About this place</p>
        <p v-if="hotelData.description && hotelData.description.length > 0">
          {{ truncatedDescription }}
          {{ hotelData.description[0].description }}
        </p>
        <p v-else>
          Transparent Policies: Clearly communicate Dormi's policies regarding
          booking, cancellation, refunds, and customer support. Providing
          transparent and fair policies can give customers confidence in their
          decision to choose Dormi-hosted hotels.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showservicemodel: false,
    };
  },
  props: {
    hotelData: {
      type: Array,
      required: true,
    },
  },

  methods: {
    showservicemodeldata() {
      this.showservicemodel = !this.showservicemodel;
    },
    getRandomColorClass() {
      const colors = [
        "text-red-500",
        "text-blue-500",
        "text-green-500",
        "text-purple-500",
        "text-yellow-500",
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
  },
};
</script>

<style>
/* Add any additional styling if needed */
.list-disc li {
  list-style-type: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.content-box {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  text-align: center;
}
.content {
  background-color: rgb(226, 223, 241);
}
</style>
