
<template>
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-8">
    <!-- ... Your existing table code ... -->
    {{ $store.state.isLoading}}
    <div v-if="state" class="text-center py-4">
      <i class="fas fa-spinner fa-spin fa-3x"></i>
      <p>Loading...</p>
    </div>
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead
        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
      >
        <tr>
          <th scope="col" class="px-6 py-3">Hotel Name</th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center">
              Locataion
              <a href="#"
                ><svg
                  class="w-3 h-3 ml-1.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"
                  /></svg
              ></a>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center">
              Totol Room
              <a href="#"
                ><svg
                  class="w-3 h-3 ml-1.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"
                  /></svg
              ></a>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center">
              Type
              <a href="#"
                ><svg
                  class="w-3 h-3 ml-1.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"
                  /></svg
              ></a>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <span class="sr-only">Edit & Delete</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
          v-for="hotel in hotels"
          :key="hotel.id"
        >
          <th
            scope="row"
            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          >
            {{ hotel.name }}
          </th>
          <td class="px-6 py-4">
            {{ hotel.hAdress2 }}
          </td>
          <td class="px-6 py-4">
            {{ hotel.totalroom }}
          </td>
          <td class="px-6 py-4">
            {{ hotel.htype }}
          </td>
          <td class="px-6 py-4 text-right">
            <a
              href="#"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline mx-4"
            >
            <router-link :to="{ name: 'hotelEdit', params: { index: hotel.id } }"> <i class="fas fa-edit"></i></router-link>
         
              <!-- Edit Icon -->
            </a>
            <a
              href="#"
              class="font-medium text-red-600 dark:text-red-500 hover:underline mx-4"
            >
              <button @click="Addinghoteimage(hotel.id)">
                <i class="fas fa-plus"></i>
              </button>
              <!-- Delete Icon -->
            </a>
            <a
              href="#"
              class="font-medium text-red-600 dark:text-red-500 hover:underline mx-4"
            >
              <button @click="deletehotel(hotel.id)">
                <i class="fa fa-trash-o"></i>
              </button>
              <!-- Delete Icon -->
            </a>
            <a
              href="#"
              class="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2 mx-4"
            >
            <router-link :to="{ name: 'hotelViewData', params: { index: hotel.id } }"> <i class="fas fa-eye"></i></router-link>
           
               
            
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination controls -->
    <div
      class="px-4 py-3 bg-gray-100 dark:bg-gray-800 border-t dark:border-gray-700"
    >
      <nav class="block">
        <ul class="flex pl-0 rounded list-none flex-wrap">
          <li v-for="pageNumber in pageCount" :key="pageNumber">
            <a
              @click.prevent="changePage(pageNumber)"
              :class="[
                currentPage === pageNumber
                  ? 'bg-blue-600 text-white'
                  : 'text-blue-600 dark:text-blue-400',
                'hover:bg-blue-600 dark:hover:bg-blue-400 hover:text-white px-3 py-1 font-semibold rounded-full',
              ]"
            >
              {{ pageNumber }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div
    class="max-w-md mx-auto p-6 bg-white rounded shadow-md absolute top-10 left-0 right-0"
    v-if="addingpicture"
  >
    <form @submit.prevent="uploadPhoto">
      <div class="flex items-center justify-center">
        <label
          class="w-32 h-32 border-4 border-dashed border-gray-400 rounded-lg cursor-pointer hover:border-blue-500 transition duration-300"
        >
          <input type="file" ref="photoInput" accept="image/*" class="hidden" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-16 h-16 mx-auto text-gray-400"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
        </label>
      </div>
      <div class="mt-4">
        <label for="caption" class="block text-sm font-medium text-gray-600"
          >Image Caption:</label
        >
        <input
          type="text"
          v-model="caption"
          placeholder="Caption"
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div class="mt-4">
        <button
          type="submit"
          class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
        >
          Submit
        </button>
        <button
          class="px-4 py-2 bg-red-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200 mx-1"
          @click="turnoff"
        >
          close
        </button>
      </div>
    </form>
  </div>
</template>
  
  <script>
import axios from "axios";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      currentPage: 1,
      hotelid: null,
      caption: "",
      isLoading: false, // Add a loading flag
      addingpicture: false,
      itemsPerPage: 10, // Number of items to show per page
    };
  },
  computed: {
    ...mapGetters(["getHotels"]), // Map the getHotels getter to this component
    hotels() {
      
      return this.getHotels;
    },
    pageCount() {
      return Math.ceil(this.hotels.length / this.itemsPerPage);
    },
    displayedHotels() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.hotels.slice(startIndex, endIndex);
    },
  },
  methods: {
    ...mapActions(["DELETEHOTEL"]),
    deletehotel(hotelid) {
      const sure = confirm("You want to delete this host?");

      if (sure) {
        this.DELETEHOTEL(hotelid);
      }
    },
    changeContent(item, hotelid) {
      this.$store.dispatch("UPDATEVIEWHOTELID", hotelid);
      this.$store.dispatch("selectItem", item);
    },
    async uploadPhoto() {
      const formData = new FormData();
      formData.append("photo_url", this.$refs.photoInput.files[0]);
      formData.append("hotel_id", this.hotelid);
      formData.append("caption", this.caption);

      try {
        const response = await axios.post(
          " https://api.dormi.co.tz/api/uploadphoto",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Photo uploaded:", response.data);

        alert("uploaded sucessifully");
        this.addingpicture = false;
        // You can handle success here, e.g., show a success message
      } catch (error) {
        console.error("Error uploading photo:", error);
        // Handle errors here, e.g., show an error message
      }
    },

    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },

    Addinghoteimage(hotelid) {
      this.addingpicture = true;
      this.hotelid = hotelid;
      console.log(this.hotelid);
    },

    turnoff() {
      this.addingpicture = false;
    },
  },
};
</script>

 