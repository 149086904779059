<template>
  
   <div class="box-container" v-if="countdataHotel">
        <div class="box box1">
          <div class="text">
           
            <h2 class="topic-heading">{{ countdataHotel.hotel }}</h2>
            <h2 class="topic">Hotel</h2>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
        </div>

        <div class="box box2">
          <div class="text">
            <h2 class="topic-heading">{{ countdataHotel.guest }}</h2>
            <h2 class="topic">Guest</h2>
          </div>

          <img
            src="../../Admin/asset/png-clipart-hotel-restaurante-cortijo-del-arte-bed-mattress-room-computer-icons-distinguished-guest-mattress-furniture.png"
            alt="likes"
          />
        </div>

        <div class="box box3">
          <div class="text">
            <h2 class="topic-heading">0</h2>
            <h2 class="topic">Comments</h2>
          </div>

          <img
            src="https://media.geeksforgeeks.org/wp-content/uploads/20221210184645/Untitled-design-(32).png"
            alt="comments"
          />
        </div>

        <div class="box box4">
          <div class="text">
            <h2 class="topic-heading">{{ countdataHotel.allbooking }}</h2>
            <h2 class="topic">Total Booking</h2>
          </div>

          <img
            src="https://media.geeksforgeeks.org/wp-content/uploads/20221210185029/13.png"
            alt="published"
          />
        </div>
      </div>

      <Table/>
</template>

<script >
import axios from 'axios';
import Table from './table-container.vue';


export default{
  data() {
    return {
      countdataHotel: [],
    };
  },
  components:{
    Table
  },
  mounted() {
    this.fetchHotelInfo();
  },
  methods: {
    async fetchHotelInfo() {
      try {
        const response = await axios.get(' https://api.dormi.co.tz/api/hotelinfo');
        this.countdataHotel = response.data; // Assuming your API response returns an array of hotel information
        this.loading = false;
      } catch (error) {
        console.error('Error fetching hotel information:', error);
        this.loading = false;
      }
    },
  },
}

</script>

