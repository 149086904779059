<template>
  <footer class="relative bg-blueGray-200 pt-8 pb-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-left lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold text-blueGray-700">Why Dormi?</h4>
          <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
            We provide high-quality and excellent services. You're welcome!
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <button
              class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://m.facebook.com/p/Dormicotz-100083036476740/">
                <i class="fab fa-facebook-square"></i>
              </a>
            </button>
            <button
              class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <a href="https://twitter.com/dormi_us">
                <i class="fab fa-twitter"></i>
              </a>
            </button>
            <button
              class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-dribbble"></i>
            </button>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
                >Useful Links</span
              >
              <ul class="list-unstyled">
                <li>
                  <router-link
                    to="/"
                    class="text-gray-600 hover:text-indigo-500"
                  >
                    Home
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/searchingInvoice"
                    class="text-gray-600 hover:text-indigo-500"
                  >
                    My Invoice
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/help"
                    class="text-gray-600 hover:text-indigo-500"
                  >
                    Help
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="block uppercase text-blueGray-500 text-sm  mb-2"
                >Other Resources</span
              >
              <ul class="list-unstyled">
                <li>
                  <a
                    class="text-blueGray-600 hover-text-blueGray-800  block pb-2 text-sm"
                    href="#"
                    >Our team</a
                  >
                </li>
                <li>
                  <span
                    class="text-blueGray-600 hover-text-blueGray-800  block pb-2 text-sm"
                    href="https://creative-tim.com/terms?ref=njs-profile"
                  >
                    <router-link
                      to="/terms"
                      class="text-gray-600 hover:text-indigo-500"
                    >
                      Terms
                    </router-link>
                  </span>
                </li>
                <li>
                  <span
                    class="text-blueGray-600 hover-text-blueGray-800  block pb-2 text-sm"
                  >
                    <router-link
                      to="/contactus"
                      class="text-gray-600 hover:text-indigo-500"
                    >
                      Contact Us
                    </router-link>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-blueGray-500 font-semibold py-1">
            Created by <span id="get-current-year">{{ currentYear }}</span>
            <a
              href="#"
              class="text-blueGray-500 hover-text-gray-800"
              target="_blank"
            >
              dormi
            </a>
            <!-- This </a> tag is not properly closed -->
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    role() {
      return this.$store.state.role;
    },
  },
  mounted() {
    var Tawk_API = Tawk_API || {};
    // eslint-disable-next-line
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/653734c1f2439e1631e7bbf4/1hdfqc5u8";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  },
};
</script>

<style>
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid rgb(202, 192, 192);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
</style>
