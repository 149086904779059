<template>
  <header
    class="flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-gray-800 text-sm py-4 dark:bg-white"
  >
     

    <nav
      class="max-w-[85rem] pt-4 w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
      aria-label="Global"
    >
      <div class="flex items-center justify-between" v-if="hotelinfo.hotelinfo">

        

        <a
          class="flex-none text-xl uppercase font-semibold text-white dark:text-gray-800 hotel-name"
          href="#"
        >
          {{ hotelinfo.hotelinfo[0].name }}
        </a>
        <div class="sm:hidden">
          <button
            class="text-3xl md:hidden focus:outline-none cursor-pointer"
            id="hamburger-button"
            @click="showmenubar = true"
          >
            &#9776;
          </button>

          <nav
            class="fixed inset-0 flex text-dark pt-18 space-x-8"
            aria-label="main"
            v-if="showmenubar"
          >
            <section class="bg-black p-6 flex flex-col shadow-3xl relative">
              <button
                class="absolute top-2 right-2 text-white hover:text-red-500 transition duration-300 transform hover:scale-110 mb-2"
               @click="showmenubar = false"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <ul class="text-white mb-2">
                <li class="flex items-center space-x-2">
                  <svg
                    class="w-5 h-5 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    ></path>
                  </svg>
                  <router-link to="/pageformanager" class="hover:text-blue-500">
                    Dashboard
                  </router-link>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    class="w-5 h-5 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                  <router-link to="/editingmanager" class="hover:text-blue-500">
                    Manage
                  </router-link>>

                  
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    class="w-5 h-5 text-gray-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg>
                  <router-link to="/password/reset">Setting</router-link>
                </li>
                <li>
                  <button
          @click="logout"
          class="flex items-center gap-x-3.5 py-2 px-4 text-sm text-white rounded-md"
        >
          <i class="fas fa-sign-out-alt"></i> Logout
        </button>
                </li>
              </ul>
            </section>
          </nav>
        </div>
      </div>
      <div
        id="navbar-dark"
        class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
      >
        <div
          class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:pl-5"
        >
          <div class="flex items-center gap-x-3.5">
            <img
              :src="picturename"
              alt="Profile Picture"
              class="w-8 h-8 rounded-full cursor-pointer"
            />
          </div>
        </div>
      </div>
    </nav>
  </header>
  <header
    class="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-blue-600 text-sm py-4"
  >
    <nav
      class="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
      aria-label="Global"
    >
      <div class="flex items-center justify-between">
        <a class="flex-none text-xl font-semibold text-white" href="#"></a>
        <div class="sm:hidden">
          <button
            type="button"
            class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border border-white/[.25] font-medium bg-blue-600 text-white shadow-sm align-middle hover:bg-white/[.15] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-600 focus:ring-white transition-all text-sm"
            data-hs-collapse="#navbar-primary"
            aria-controls="navbar-primary"
            aria-label="Toggle navigation"
          >
            <svg
              class="hs-collapse-open:hidden w-4 h-4"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
            <svg
              class="hs-collapse-open:block hidden w-4 h-4"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        id="navbar-primary"
        class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block"
      >
        <div
          class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:pl-5"
        >
          <a class="font-medium text-white" href="#" aria-current="page"
            >Landing</a
          >
          <a class="font-medium text-gray-300 hover:text-white" href="#"
            >Account</a
          >
          <a class="font-medium text-gray-300 hover:text-white" href="#"
            >Work</a
          >
          <a class="font-medium text-gray-300 hover:text-white" href="#"
            >Blog</a
          >
        </div>
      </div>
    </nav>
  </header>
  <!-- Main Content -->
  <div class="main-container">
    <div class="navcontainer">
      <Sidebar class="sidebar" />
    </div>
    <maincomponent :name="manager" />
  </div>

  <!-- Mobile Menu -->
  <div
    class="top-10 text-black fixed z-50 left-0 mx-auto rounded-lg"
    v-if="showmenu"
  >
    <div
      class="mt-10 border p-6 bg-white font-medium w-96 flex flex-col shadow-lg h-96"
    >
      <ul>
        <router-link to="/manager">
          <li><i class="fas fa-chart-bar"></i> Dashboard</li>
        </router-link>

        <br />

        <router-link to="/policy">
          <li><i class="fas fa-chart-bar"></i> Adding Hotel</li>
        </router-link>

        <br />

        <li><i class="fas fa-calendar-plus"></i> Booking</li>

        <br />

        <li><i class="fas fa-user"></i> Profile</li>

        <br />

        <li><i class="fas fa-file-alt"></i> Generate Report</li>

        <br />

        <li><i class="fas fa-sign-out-alt"></i> Logout</li>
      </ul>
    </div>
  </div>
</template>

  
  <script>
import store from "../store/index";
import { mapActions } from "vuex";
import Sidebar from "../manager/components/sidebar-component.vue";
import maincomponent from "../manager/components/Main-component.vue";
import axios from "axios";
export default {
  ...mapActions(["logout"]),
  data() {
    return {
      changedata: false,
      selectedItem: "dashboard",
      menubaricon: false,
      showmenu: false,
      search: "",
      hotelid: "",
      hotelinfo: {},
      manager: {},
      showmenubar: false,
      picturename: "",
    };
  },
  components: {
    maincomponent,
    Sidebar,
  },
  methods: {
    async searchinghotel() {
      const formidata = {
        hotelid: this.hotelid,
        name: this.search,
      };

      const response = await axios.post(
        "https://api.dormi.co.tz/api/searchinguser",
        formidata
      );
      console.log(response);
    },

    async hotelInformation() {
      const formdata = {
        hotelid: this.hotelid,
      };

      const response = await axios.post(
        "https://api.dormi.co.tz/api/hotelinfomationformanager",
        formdata
      );
      this.hotelinfo = response.data;
    },

    logout() {
      this.$store.dispatch("logout");
      // Redirect to /
      this.$router.push("/");
    },

    async fetchHotels() {
      
      const forminfo = {
        hotelid: this.hotelid,
      };
      try {
        const response = await axios.post(
          "https://api.dormi.co.tz/api/feachingorderbooking",
          forminfo
        );
        this.hotelinfo = response.data;
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    },

    async getprofilepicture() {
      const userid = this.manager.user_id;
      const formdata = {
        userid: userid,
      };
      const response = await axios.post(
        "https://api.dormi.co.tz/api/userprofile",
        formdata
      );
      this.picturename = response.data;
    },
  },

  mounted() {
    this.fetchHotels();

    this.manager = store.state.manager;
    this.hotelid = this.manager.hotelid;
    this.getprofilepicture();
    this.hotelInformation();
    console.log(this.manager.user_id);
  },
};
</script>
  <style >
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.body {
  background-color: snow;
}

header {
  height: 70px;
  width: 100vw;
  padding: 0 30px;
  background-color: #fafaff;
  position: fixed;
  z-index: 100;
  box-shadow: 1px 1px 15px rgba(161, 182, 253, 0.825);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 27px;
  font-weight: 600;
  color: rgb(47, 141, 70);
}

.icn {
  height: 30px;
}
.menuicn {
  cursor: pointer;
}

.searchbar,
.message,
.logosec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar2 {
  display: none;
}

.logosec {
  gap: 60px;
}

.searchbar input {
  width: 250px;
  height: 42px;
  border-radius: 50px 0 0 50px;
  background-color: #ededed;
  padding: 0 20px;
  font-size: 15px;
  outline: none;
  border: none;
}
.searchbtn {
  width: 50px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 50px 50px 0px;
  background-color: #0c007d;
  cursor: pointer;
}

.message {
  gap: 40px;
  position: relative;
  cursor: pointer;
}
.circle {
  height: 7px;
  width: 7px;
  position: absolute;
  background-color: #fa7bb4;
  border-radius: 50%;
  left: 19px;
  top: 8px;
}
.dp {
  height: 40px;
  width: 40px;
  background-color: #626262;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.main-container {
  display: flex;
  width: 100vw;
  position: relative;
  top: 70px;
  z-index: 1;
}
.dpicn {
  height: 42px;
}

.main {
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px 30px 30px 30px;
}

.main::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, rgb(0, 0, 85), rgb(0, 0, 50));
}
.main::-webkit-scrollbar {
  width: 5px;
}
.main::-webkit-scrollbar-track {
  background-color: #9e9e9eb2;
}

.box-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
.nav {
  min-height: 91vh;
  width: 250px;
  background-color: #d1c8c8;
  position: absolute;
  top: 0px;
  left: 00;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border: 1px solid blue;
  padding: 30px 0 20px 10px;
}
.navcontainer {
  height: calc(100vh - 70px);
  width: 250px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
}
.navcontainer::-webkit-scrollbar {
  display: none;
}
.navclose {
  width: 80px;
}
.nav-option {
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 20px;
  gap: 20px;
  transition: all 0.1s ease-in-out;
}
.nav-option:hover {
  border-left: 5px solid #a2a2a2;
  background-color: #dadada;
  cursor: pointer;
}
.nav-img {
  height: 30px;
}

.nav-upper-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.option1 {
  border-left: 5px solid #010058af;
  background-color: #3f0097;
  color: white;
  cursor: pointer;
}
.option1:hover {
  border-left: 5px solid #010058af;
  background-color: #3f0097;
}
.box {
  height: 130px;
  width: 230px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.box:hover {
  transform: scale(1.08);
}

.box:nth-child(1) {
  background-color: #3f0097;
}
.box:nth-child(2) {
  background-color: #5500cb;
}
.box:nth-child(3) {
  background-color: #3f0097;
}

.box:nth-child(4) {
  background-color: #5500cb;
}

.box img {
  height: 50px;
}
.box .text {
  color: white;
}
.topic {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
}

.topic-heading {
  font-size: 30px;
  letter-spacing: 3px;
}

.report-container {
  min-height: 300px;
  max-width: 1200px;
  margin: 70px auto 0px auto;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 3px 3px 10px rgb(188, 188, 188);
  padding: 0px 20px 20px 20px;
}
.report-header {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  border-bottom: 2px solid rgba(0, 20, 151, 0.59);
}

.recent-Articles {
  font-size: 30px;
  font-weight: 600;
  color: #5500cb;
}

.view {
  height: 35px;
  width: 90px;
  border-radius: 8px;
  background-color: #5500cb;
  color: white;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

.report-body {
  max-width: 1160px;
  overflow-x: auto;
  padding: 20px;
}
.report-topic-heading,
.item1 {
  width: 1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-op {
  font-size: 18px;
  letter-spacing: 0px;
}

.items {
  width: 1120px;
  margin-top: 15px;
}

.item1 {
  margin-top: 20px;
}
.t-op-nextlvl {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
}

.label-tag {
  width: 100px;
  text-align: center;
  background-color: rgb(0, 177, 0);
  color: white;
  border-radius: 4px;
}

/* Responsive CSS Here */
@media screen and (max-width: 950px) {
  .nav-img {
    height: 25px;
  }
  .nav-option {
    gap: 30px;
  }
  .nav-option h3 {
    font-size: 15px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 800px;
  }
}

@media screen and (max-width: 850px) {
  .nav-img {
    height: 30px;
  }
  .nav-option {
    gap: 30px;
  }
  .nav-option h3 {
    font-size: 20px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 700px;
  }
  .navcontainer {
    width: 100vw;
    position: absolute;
    transition: all 0.6s ease-in-out;
    top: 0;
    left: -100vw;
  }
  .nav {
    width: 100%;
    position: absolute;
  }
  .navclose {
    left: 00px;
  }
  .searchbar {
    display: none;
  }
  .main {
    padding: 40px 30px 30px 30px;
  }
  .searchbar2 {
    width: 100%;
    display: flex;
    margin: 0 0 40px 0;
    justify-content: center;
  }
  .searchbar2 input {
    width: 250px;
    height: 42px;
    border-radius: 50px 0 0 50px;
    background-color: #ededed;
    padding: 0 20px;
    font-size: 15px;
    border: 2px solid #0c007d;
  }
}

@media screen and (max-width: 490px) {
  .message {
    display: none;
  }
  .logosec {
    width: 100%;
    justify-content: space-between;
  }
  .logo {
    font-size: 20px;
  }
  .menuicn {
    height: 25px;
  }
  .nav-img {
    height: 25px;
  }
  .nav-option {
    gap: 25px;
  }
  .nav-option h3 {
    font-size: 12px;
  }
  .nav-upper-options {
    gap: 15px;
  }
  .recent-Articles {
    font-size: 20px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 550px;
  }
}

@media screen and (max-width: 400px) {
  .recent-Articles {
    font-size: 17px;
  }
  .view {
    width: 60px;
    font-size: 10px;
    height: 27px;
  }
  .report-header {
    height: 60px;
    padding: 10px 10px 5px 10px;
  }
  .searchbtn img {
    height: 20px;
  }
}

@media screen and (max-width: 320px) {
  .recent-Articles {
    font-size: 12px;
  }
  .view {
    width: 50px;
    font-size: 8px;
    height: 27px;
  }
  .report-header {
    height: 60px;
    padding: 10px 5px 5px 5px;
  }
  .t-op {
    font-size: 12px;
  }
  .t-op-nextlvl {
    font-size: 10px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 300px;
  }
  .report-body {
    padding: 10px;
  }
  .label-tag {
    width: 70px;
  }
  .searchbtn {
    width: 40px;
  }
  .searchbar2 input {
    width: 180px;
  }
}
</style>