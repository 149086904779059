<template>
   <Nav></Nav>
    <!-- Container for demo purpose -->
  <div class="container my-24 mx-auto md:px-6">
    <!-- Section: Design Block -->
    <section class="mb-32">
      <div
        class="relative h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat bg-[url('https://images.woodenstreet.de/image/data/bed-with-storage-mdf/harper-bed-with-box-storage/exotic-teak/updated/1.jpg')]"
      ></div>
      <div class="container px-6 md:px-12">
        <div
          class="block rounded-lg px-6 py-12 shadow-lg bg-white md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]"
        >
          <div class="flex flex-wrap">
            <div
              class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6"
            >
              <form @submit.prevent="submitForm" class="max-w-md mx-auto">
                <div class="mb-4">
                  <input
                    type="text"
                    v-model="firstName"
                    required
                    class="w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary"
                    placeholder="Enter Your invoice reference Number"
                  />
                </div>
              
              
                
                <button
                  type="submit"
                  class="w-full bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-600 focus:outline-none focus:bg-primary-600"
                >
                  search my invoice
                </button>
              </form>
            </div>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <p>Comming Soon....</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Section: Design Block -->
  </div>
   <Footer></Footer>
</template>



<script>
import Nav from "../components/Navbar-component.vue";
import Footer from "../components/Footer-Component.vue";
export default {
  name: "TermsOfService",
  components: {
    Nav,
    Footer,
  },
};
</script>