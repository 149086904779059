<template>
  <div>
    <nav class="bg-white shadow-lg">
      <div class="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16 items-center">
          <div class="flex">
            <!-- Logo -->
            <router-link
              to="/"
              class="flex-shrink-0 flex items-center hidden md:block sm:block"
            >
              <img
                class="h-8 w-auto logo"
                src="../assets/hotelimage/log.png"
                alt="Logo"
              />
            </router-link>
          </div>
          <!-- Centered Content -->
          <div class="flex flex-grow justify-center">
            <div class="flex justify-between items-center">
              <router-link to="/" class="mx-2 sm:mx-5 md:hidden">
                <img
                  class="h-8 w-auto"
                  src="../assets/hotelimage/log.png"
                  alt="Logo"
                />
              </router-link>

              <div class="w-2/4 sm:w-3/4">
                <div class="relative text-gray-600">
                  <input
                    type="search"
                    name="search"
                    placeholder="Search destinations"
                    class="w-full pl-10 pr-4 py-2 rounded-lg shadow focus:outline-none focus:shadow-outline"
                    @input="handleSearchInput"
                    ref="searchInput"
                  />
                  <div
                    class="absolute top-0 left-0 mt-2 ml-3"
                    @click="handleSearchClick"
                  >
                    <svg
                      class="w-6 h-6 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 15l6 6-6-6zM10 4a6 6 0 100 12 6 6 0 000-12z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="mx-2 sm:mx-5 md:hidden">
                <button
                  @click="toogle"
                  class="text-gray-600 hover:text-gray-800"
                >
                  <i class="fas fa-bars text-2xl"></i>
                </button>
          
              </div>
            </div>
          </div>

          <!-- User Avatar -->
          <div
            class="info flex flex-row gap-2 border border-solid items-center hidden md:flex sm:flex border-spacing-1 bg-white border-slate-50 l rounded-lg shadow-lg justify-center"
          >
            <span class="popup" @click="me">
              <i class="fas fa-bars fa-1x"></i>
            </span>

            <span>
              <img
                class="h-8 w-auto logo"
                v-if="logoAvailable"
                src="logo.png"
                alt="Logo"
              />
              <i class="fas fa-user-circle text-gray-500 ml-2" v-else></i>
            </span>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="loading" class="text-center mt-2">
      <i class="fas fa-spinner fa-spin"></i> Loading...
    </div>
  </div>

  <div
    v-if="popup"
    class="popupcontainer pop h-30 flex flex-col gap-1 text-start px-2 py-5 rounded-lg"
  >
    <router-link v-if="!loggedIn" to="/login">
      <p><i class="fas fa-sign-in-alt"></i> Login</p>
    </router-link>
    <div v-else>
      <h2>Hello, user</h2>
      <!-- ... Other content for logged-in users ... -->
      <button @click="logout">
        <i class="fas fa-sign-out-alt"></i> Logout
      </button>
    </div>
    <hr />
    <div>
      <div v-if="role == 'admin'">
        <router-link to="/homepage"
          ><i class="fas fa-tachometer-alt"></i> Dashboard</router-link
        >
      </div>
      <div v-if="role == 'manager'">
        <router-link to="/pageformanager"
          ><i class="fas fa-tachometer-alt"></i> Dashboard</router-link
        >
      </div>
      <p>
        <router-link to="/">
          <i class="fas fa-home fa mr-2"></i>
          <span>Home</span>
        </router-link>
      </p>
    </div>
    <div>
      <p>
        <router-link to="/help">
          <i class="fas fa-question-circle fa mr-2"></i>
          <span>Help</span>
        </router-link>
      </p>
    </div>
  </div>

  <div v-if="menuOpen" class="menu-content bg-slate-300 fixed top-0 left-0 w-screen h-screen flex flex-col justify-between items-start" style="z-index: 10000; padding: 20px;">
  <div>
    <button @click="closeMenu" class="flex items-center text-gray-700 hover:text-blue-600 text-2xl p-4">
      <i class="fas fa-times mr-2"></i> Close
    </button>
    <ul class="space-y-2 p-4">
      <li>
        <router-link to="/searchingInvoice">
          <a class="flex items-center text-gray-700 hover:text-blue-600">
            <i class="fas fa-file-invoice mr-2"></i> My Invoices
          </a>
        </router-link>
      </li>
      <li v-if="role === 'admin'">
        <router-link to="/homepage">
          <i class="fas fa-tachometer-alt mr-2"></i> Dashboard
        </router-link>
      </li>
      <li v-if="role === 'manager'">
        <router-link to="/pageformanager">
          <i class="fas fa-tachometer-alt mr-2"></i> Dashboard
        </router-link>
      </li>
      <li>
        <router-link v-if="!loggedIn" to="/login">
          <i class="fas fa-sign-in-alt mr-2"></i> Login
        </router-link>
      </li>
      <li>
        <router-link to="/contactus">
          <i class="fas fa-envelope mx-1"></i> Contact Us
        </router-link>
      </li>
      <li>
        <router-link to="/help">
          <i class="fas fa-question mx-1"></i> Help
        </router-link>
      </li>
    </ul>
  </div>
</div>


</template>

<script>
import axios from "axios";

import { mapActions } from "vuex";

export default {

 
  mounted() {
    console.log("Component mounted.");
  },
  data() {
    return {
      hotel: [],
      menuOpen:false,
      searchQuery: "",
      searchResults: [],
      popup: false,

      loading: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    role() {
      return this.$store.state.role;
    },

    username() {
      return this.$store.state.name;
    },
  },
  methods: {
    toogle()
    {
      this.menuOpen = true;
    },
    closeMenu()
    {
      this.menuOpen = false;    },
    
    ...mapActions(["logout"]),
    me() {
      this.popup = !this.popup;
      console.log(this.popup);
    },
    toggleSearchForm() {
      this.showdate = !this.showdate; // Toggle the 'showSearchForm' flag
    },
    async handleSearchInput(event) {
      // Handle the search input changes directly using the event.target.value
      // You can perform any logic you need based on the input value here.
      this.searchQuery = event.target.value;
      console.log(this.searchQuery);
    },
    async handleSearchClick() {
      try {
        const response = await axios.get(
          `  https://api.dormi.co.tz/api/searchinghotel?q=${this.searchQuery}`
        );
        const searchResults = response.data;
        if (searchResults.length === 0) {
          // If searchResults is empty, display a message to the user
          console.log("No search results found.");
        } else {
          // If searchResults is not empty, store the data in Vuex and navigate to the search results page
          this.searchResults = searchResults;
          this.$store.commit("SET_SEARCH_RESULTS", searchResults);
          this.$router.push({
            name: "search-results",
          });
        }

        this.$store.commit("SET_SEARCH_RESULTS", searchResults);
        this.$router.push({
          name: "search-results",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false; // Set loading to false in case of an error
      }
    },
  },
};
</script>

<style>
/* Responsive styles for the logo */
.logo {
  display: none;
}

@media (min-width: 640px) {
  .logo {
    display: block;
  }
}

nav {
  position: fixed;
  left: 0;
  top: 0;
  margin-bottom: 30px;
  width: 100%;
  border-top: 1px solid rgb(202, 192, 192);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.info {
  width: 80px;
  height: 50px;
  border-radius: 1px solid black;
}
.popupcontainer {
  position: fixed;
  top: 50px;
  z-index: 100000;
  background-color: beige;
  right: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  height: 150px;
  width: 200px;
}

.horizontal {
  width: 100%;
  background: silver;
  height: 1px;
}
</style>
