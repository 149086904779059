<template>
  <div class="container max-auto px-8 rounded-md w-100">
    <div class="detail flex flex-col gap-2">
      <div class="flex flex-row justify-between">
        <div></div>
        <div v-if="hotels">
          Tsz {{ hotels.price.toLocaleString() }} per night
          <i class="fa fa-star" aria-hidden="true"></i> 4.78
          <span>reviews</span>
        </div>
      </div>
      <div>
        <div class="flex flex-col">
          <div class="flex flex-row justify-between mt-3 booking">
            <div class="checkin px-1">
              <p>Check-in</p>
              <input
                type="date"
                :value="$store.state.checkinDate || getTodayDate()"
                @input="$store.commit('setCheckinDate', $event.target.value)"
              />
            </div>
            <div class="line"></div>
            <div class="checkout px-5">
              <p>Check-out</p>
              <input
                type="date"
                :value="$store.state.checkoutDate || getTomorrowDate()"
                @input="$store.commit('setCheckoutDate', $event.target.value)"
              />
            </div>
          </div>
          <div class="addingpeople">
            <div class="flex flex-row justify-between mt-3">
              <div>
                <label for="adults">Adults:</label>
                <div class="quantity-container">
                  <button @click="increment('adults')">+</button>
                  <span>{{ $store.state.selectedAdults }}</span>
                  <button @click="decrement('adults')">-</button>
                </div>
              </div>

              <div>
                <label for="children">Children:</label>
                <div class="quantity-container">
                  <button @click="decrement('children')">-</button>
                  <span>{{ $store.state.selectedChildren }}</span>
                  <button @click="increment('children')">+</button>
                </div>
              </div>

              <div>
                <label for="infants">Infants:</label>
                <div class="quantity-container">
                  <button @click="decrement('infants')">-</button>
                  <span>{{ $store.state.selectedInfants }}</span>
                  <button @click="increment('infants')">+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="booking-button-container" v-if="hotelData">
          <button
            v-if="hotelData.totalroom > 0"
            @click="navigateToBooking(hotelData)"
          >
            <span class="book-now-button">Book Now</span>
          </button>
          <span v-else>
            <span class="book-now-button">No room available</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!--------------------error about checkin and checkout------------------->
  <div
    v-if="$store.state.checkinError"
    class="modal fixed inset-0 flex items-center justify-center"
  >
    <div class="modal-content bg-white rounded-lg shadow-lg p-4 relative">
      <p>{{ $store.state.checkinError }}</p>
      <button
        class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        @click="$store.commit('clearCheckinError')"
      >
        Close
        <i class="fas fa-times ml-2"></i>
      </button>
    </div>
  </div>

  <div
    v-if="$store.state.checkoutError"
    class="modal fixed inset-0 flex items-center justify-center"
  >
    <div class="modal-content bg-white rounded-lg shadow-lg p-4 relative">
      <p>{{ $store.state.checkoutError }}</p>
      <button
        class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        @click="$store.commit('clearCheckoutError')"
      >
        Close
        <i class="fas fa-times ml-2"></i>
      </button>
    </div>
  </div>
  <!---------------------------------endcheckoutand checkin error-------->
</template>
  
  <script>
export default {
  data() {
    return {
      selectedAdults: 1,
      selectedChildren: 0,
      selectedInfants: 0,
      isLoading: false,
    };
  },
  props: {
    hotelData: {
      type: Object, // Adjust the type according to your data structure
      required: true,
    },
  },
  computed: {
    hotels() {
      return this.hotelData;
    },
  },
  mounted() {
    this.$store.commit("setSelectedAdults", this.selectedAdults);
    this.$store.commit("setSelectedChildren", this.selectedChildren);
    this.$store.commit("setSelectedInfants", this.selectedInfants);
    this.getTodayDate();
    this.getTomorrowDate();
  },
  methods: {
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const year = tomorrow.getFullYear();
      const month = tomorrow.getMonth() + 1;
      const day = tomorrow.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    navigateToBooking(item) {
      // Assuming you have a route named 'hotelProfile' defined in your router
      this.$router.push({ name: "Booking", params: { hotelId: item.id } });
    },
    increment(category) {
      this.$store.commit("incrementQuantity", category);
    },
    decrement(category) {
      this.$store.commit("decrementQuantity", category);
    },
  },
};
</script>
  
  <style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.detail {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkin,
.checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  width: 1px;
  height: 20px;
  background-color: #d1cacac7;
  margin: 0 10px;
}

.booking {
  border: 1px solid #c0b2b2;
  padding: 10px;
  border-radius: 4px;
}

.quantity-container {
  display: flex;
  align-items: center;
}

.quantity-container button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  cursor: pointer;
}

.quantity-container span {
  margin: 0 10px;
  font-weight: bold;
}

label {
  font-weight: bold;
}

button {
  outline: none;
}

p {
  margin: 0;
}

.fa-star {
  color: gold;
}

.booking-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.booking-button {
  padding: 10px 20px;
  background-color: #ff00dd;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

input[type="date"] {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
}

.error-message {
  color: red;
}

.book-now-button {
  display: inline-block;
  background-color: #dda2a2; /* Replace with your desired background color */
  color: #ffffff; /* Replace with your desired text color */
  padding: 10px 20px; /* Adjust padding as needed */
  text-decoration: none;
  border-radius: 4px; /* Adjust border radius as needed */
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .book-now-button {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 10px; /* Adjust margin as needed */
  }
}
</style>
  