<template>
  <div>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div v-if="isLoading" class="text-center mt-4">
        <div class="animate-spin text-blue-500 text-4xl">
          <i class="fas fa-circle-notch"></i>
        </div>
        <p class="mt-2">Loading...</p>
      </div>
      <table
        class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        v-else
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">payment method</th>
            <th scope="col" class="px-6 py-3">phone number</th>

            <th scope="col" class="px-6 py-3">Total price</th>
            <th scope="col" class="px-6 py-3">Reference number</th>
            <th scope="col" class="px-6 py-3">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            v-for="bookings in paginatedData"
            :key="bookings.id"
          >
            <td class="px-6 py-4">
              {{ bookings.paymentmethod }}
            </td>
            <td class="px-6 py-4">
              {{ bookings.phone_number }}
            </td>
            <td class="px-10 py-4">
              {{ bookings.Totalprice.toLocaleString() }}
            </td>
            <td
              class="px-6 py-4 text-center hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105"
              @mouseover="showCopyButton = true"
              @mouseout="showCopyButton = false"
            >
              {{ bookings.reference_number }}
              <button
                v-if="showCopyButton"
                @click="copyToClipboard(bookings.reference_number)"
                class="bg-blue-500 text-white px-2 py-1 ml-2 hover:bg-blue-600 cursor-pointer"
              >
                Copy
              </button>
            </td>
            <td class="px-6 py-4 dataformat">
              <button
                v-if="bookings.status == 0"
                class="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                Fails
              </button>

              <button
                v-if="bookings.status == 2"
                class="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                panding
              </button>
              <button
                v-if="bookings.status == 3"
                class="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out transform hover:scale-105"
              >
                confirmed
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="pagination flex items-center justify-center mt-4 w-full">
    <button @click="previousPage" :disabled="currentPage === 1" class="px-4 py-2 rounded-l-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        Previous
    </button>
    <span class="px-4 py-2 bg-gray-300 text-gray-700">
        {{ currentPage }}
    </span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 rounded-r-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        Next
    </button>
</div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      bookings: [],
      itemsPerPage: 5,   // Number of items to display per page
      currentPage: 1,     // Current page
      isLoading: true,
    };
  },
  mounted() {
    this.fetchBookingData();
  },
  computed: {

    totalPages() {
      return Math.ceil(this.bookings.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.bookings.slice(start, end);
    },
    incrementedValue() {
      return 1 + 1; // Replace this with the actual value you want to increment
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    fetchBookingData() {
      setTimeout(async () => {
        axios
          .get("https://api.dormi.co.tz/api/bookingData")
          .then((response) => {
            this.bookings = response.data;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error("Error fetching booking data:", error);
            this.isLoading = false;
          });
      }, 3000); // 3000 milliseconds (3 seconds) delay
    },
  },
};
</script>

<style>
.bg-primary {
  background-color: blue; /* Change to your desired primary color */
  color: white;
}

.bg-expire {
  background-color: red; /* Change to your desired expire color */
  color: white;
}

.change {
  padding: 8px;
  border-radius: 4px;
}
</style>