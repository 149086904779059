<template>
 <div class="container mx-auto p-4 bg-white rounded-lg shadow-lg">
  <div class="shadow-lg  text-dark p-4 mb-4 rounded-t-lg">
    <p class="text-xl font-semibold">Manager Detail</p>
  </div>
  <!-- Show preloader while data is loading -->
  <div v-if="isLoading" class="text-center py-4">
      <i class="fas fa-spinner fa-spin fa-3x"></i>
      <p>Loading...</p>
    </div>
  <div class="overflow-x-auto">
    <table class="table-auto w-full border-collapse">
      <thead>
        <tr>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Sno</th>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Name</th>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Hosting Name</th>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Hosting Address</th>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Hosting Phone</th>
          <th class="px-4 py-2 sm:px-6 lg:px-8">Hosting Type</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in ManagerInfo" :key="key">
          <td class="px-4 py-2 sm:px-6 lg:px-8 flex items-center space-x-2">
            <i class="fas fa-check"></i>
  {{ key }}<!-- Replace 'fa-heart' with the desired Font Awesome icon class -->
</td>
        <td class="px-4 py-2 sm:px-6 lg:px-8">{{ value['manager'].lname }}</td>
        <td class="px-4 py-2 sm:px-6 lg:px-8">{{ value['hotel'].name }}</td>
        <td class="px-4 py-2 sm:px-6 lg:px-8">{{ value['hotel'].hadress }}</td>
        <td class="px-4 py-2 sm:px-6 lg:px-8">{{ value['hotel'].hmainPhonenumber }}</td>
        <td class="px-4 py-2 sm:px-6 lg:px-8">{{ value['hotel'].htype }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

</template>

<script>
import axios from 'axios'
export default {
     data() {
        return {
            ManagerInfo: [],
            isLoading: true, // Add a loading flag
        }
   
},
mounted() {
    this.getManagerInfo();
},

methods: {
    getManagerInfo() {
        axios.get(' https://api.dormi.co.tz/api/managerInfoDetails')
        .then(response => {
            this.ManagerInfo = response.data
            this.isLoading = false; // Set isLoading to false when data is loaded
        })
        .catch(error => {
            console.log(error)
            this.isLoading = false; // Set isLoading to false when data is loaded
        })
    }
}
}

</script>