<template>
  <div class=" min-h-screen">
    <div class="w-full py-8">
      <div class="overflow-x-auto">
        <div
          v-for="contact in paginatedData"
          :key="contact.id"
          class="bg-white shadow-md hover:shadow-lg transition duration-300 rounded-lg mb-4"
        >
          <div class="container mx-auto px-4">
            <!-- Section: Design Block -->
            <section class="py-4">
              <div class="flex flex-wrap items-center">
                <div class="w-full md:w-2/12 lg:w-3/12 text-center md:text-left mb-4 md:mb-0">
                  <i class="text-3xl text-gray-600 fas fa-envelope"></i>
                  <p class="text-gray-600 font-semibold mt-2">{{ contact.email }}</p>
                </div>

                <div class="w-full md:w-10/12 lg:w-9/12 md:pl-4">
                  <h2 class="text-2xl font-semibold mb-2">
                    {{ contact.firstName }} {{ contact.lastName }}
                  </h2>
                  
                  <p class="text-gray-800">{{ contact.message }}</p>

                  <p class="text-gray-800">{{ contact.created_at }}</p>
                </div>
              </div>
            </section>
            <!-- Section: Design Block -->
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center mt-4">
        <button
          @click="previousPage"
          :disabled="currentPage === 1"
          class="px-4 py-2 rounded-l-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <i class="fas fa-chevron-left"></i> Previous
        </button>
        <span class="px-4 py-2 bg-gray-300 text-gray-700">
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="px-4 py-2 rounded-r-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>


  
  
  <script>
import axios from "axios";

export default {
  data() {
    return {
      contactus: [],
      perPage: 10, // Number of items per page
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.contactus.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.contactus.slice(start, end);
    },
    incrementedValue() {
      return 1 + 1; // Replace this with the actual value you want to increment
    },
  },
  methods: {
    paginate(page) {
      this.currentPage = page;
    },
    fetchContactUsData() {
      axios
        .get("https://api.dormi.co.tz/api/getcontactus")
        .then((response) => {
          this.contactus = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchContactUsData();
  },
};
</script>

  