<template>
  <Nav></Nav>
  <div
    class="flex flex-col md:flex-row h-screen items-center mt-10"
    v-if="hotelData.hotel"
  >
    <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-scree">
      <img
        src="../assets/booking.jpg"
        alt="Login Background"
        class="w-full h-full object-cover"
      />
    </div>

    <div
      class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center"
    >
      <div class="w-full" v-if="!loading">
        <div class="headerbooking text-center pt-4 md:pt-9">
          <p
            class="text-3xl font-bold text-purple-800 uppercase transform hover:scale-110 transition-all"
          >
            {{ hotelData.hotel.name }}
          </p>
        </div>

        <form class="mt-6" @submit.prevent="submitForm">
          <div>
            <p
              class="notification flex items-center p-2 border border-red-500 rounded-md shadow-md"
            >
              <i
                class="fas fa-exclamation-circle text-red-500 text-xl mr-2 animate-bounce"
              ></i>
              Please complete all the required fields.
            </p>
          </div>

          <div class="mb-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
              <div class="flex-grow">
                <label for="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name"
                  class="text-center w-full"
                  v-model="gFirstName"
                />
              </div>
              <div class="flex-grow">
                <label for="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name"
                  class="text-center w-full"
                  v-model="gLastName"
                />
              </div>
              <div
                class="flex-grow grid grid-cols-1 md:grid-cols-2 gap-4 mt-5 sm:mt-1 w-full"
              >
                <div class="w-100">
                  <label for="city">City</label>
                  <div v-if="isCityValid" class="flex-grow">
                    <!-- Display the user's input if the city is valid -->
                    <input
                      type="text"
                      id="city"
                      placeholder="City"
                      class="text-center w-full"
                      v-model="gCity"
                    />
                  </div>
                  <div v-else>
                    <!-- Display a dropdown if the city is not valid -->
                    <select
                      v-model="gCity"
                      id="city"
                      class="p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                    >
                      <option value="" disabled>Select a city</option>
                      <option
                        v-for="city in tanzanianRegions"
                        :value="city"
                        :key="city"
                      >
                        {{ city }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <input
                type="text"
                id="state"
                placeholder="State"
                class="text-center w-full"
                v-model="gState"
                hidden
              />

              <input
                type="text"
                id="zipcode"
                placeholder="Zipcode"
                class="text-center w-full"
                v-model="gZipCode"
                hidden
              />

              <div class="flex-grow mt-4">
                <label for="country">Country</label>
                <input
                  type="text"
                  id="country"
                  placeholder="eg.0622 xxx xxx"
                  class="text-center w-full"
                  v-model="gCountry"
                  readonly
                />
              </div>
              <div class="flex-grow">
                <label for="homePhoneNumber">Phone Number</label>
                <input
                  type="text"
                  id="homePhoneNumber"
                  placeholder="eg.0622 xxx xxx"
                  class="text-center w-full"
                  v-model="gHomePhoneNumber"
                  @input="validatePhoneNumberi"
                />
                <span v-if="phoneValidationMessage" class="text-red-500">{{
                  phoneValidationMessage
                }}</span>
              </div>
              <div class="flex-grow">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="eg.dormi@com"
                  class="text-center w-full"
                  v-model="gEmail"
                />
              </div>
            </div>
          </div>

          <div
            class="Totalcost bg-white shadow-lg mt-4 md:mt-10 h-12 rounded-lg overflow-hidden"
          >
            <div class="flex items-center justify-center h-full">
              <p
                class="text-lg font-semibold text-black-800 animated-text mx-3"
              >
                Total Cost {{ hotelprice }}/=
              </p>

              <button
                @click="showPaymentModel"
                class="bookingbtn h-10 w-full bg-yellow-300 mb-5 relative overflow-hidden"
              >
                Booking Now
              </button>
            </div>
          </div>
        </form>

        <hr class="my-6 border-gray-300 w-full" />
      </div>

      <div v-else>
        <!-- Custom loading animation (replace with your preferred animation) -->
        <div class="flex items-center justify-center h-screen">
          <div class="custom-loader"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" v-if="showpaymentmodel">
    <div
      class="bookingcard flex justify-center items-center mx-auto mt-28"
      v-if="!ifsecessifuly"
    >
      <div
        class="container shadow-lg rounded-sm flex flex-col bg-white px-4 py-6 md:w-1/2 lg:w-1/3"
      >
        <div class="mt-1 flex flex-row gap-1">
          <div>
            <img
              src="./asset/t.png"
              alt=""
              srcset=""
              style="width: 400px; height: auto"
            />
          </div>
          <div>
            <img
              src="./asset/h.png"
              alt=""
              srcset=""
              style="width: 400px; height: auto"
            />
          </div>
          <div>
            <img
              src="./asset/v.png"
              alt=""
              srcset=""
              style="width: 400px; height: auto"
            />
          </div>
        </div>

        <div class="container mt-2">
          <input
            type="text"
            placeholder="Enter your Payment Phone number"
            @input="validatePhoneNumber"
            v-model="paymentphonenumber"
          />
          <input
            type="submit"
            value="Submit"
            class="mt-1"
            :class="{
              'bg-blue-700 cursor-pointer': isValidPhoneNumber,
              'bg-gray-400': !isValidPhoneNumber,
            }"
            @click="bookGuest"
            :disabled="!isValidPhoneNumber"
          />
          <div cla>
            <p>OR</p>
          </div>

          <button
            class="info-message bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative cursor-pointer hover:bg-red-200 w-100"
            role="button"
            @click="showData"
          >
            <i class="fas fa-money-bill mr-2"></i> Lipa Namba
          </button>

          <!-- Validation Messages -->
          <div v-if="paymentphonenumber == ''" class="info-message">
            <i class="fas fa-info-circle"></i> Please enter your phone number
          </div>
          <div v-else-if="isValidPhoneNumber" class="success-message">
            <div class="flex justify-center items-center space-x-4">
              <p class="flex items-center space-x-2">
                <i class="fas fa-check-circle text-green-500"></i>
                <span class="font-bold">Phone number is valid</span>
              </p>

              <p class="flex items-center space-x-2">
                <i class="fas fa-calendar-plus text-blue-500"></i>
                <span class="font-bold">Booking Now</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="ifsecessifuly">
      <PaymentDetail :bookingDetail="userdata" />
    </div>
  </div>

  <div class="overlay pt-10" v-if="editing">
    <bookingCard />
  </div>

  <div
    class="modal fixed inset-0 flex items-center justify-center overlay"
    v-if="showdatamodel"
  >
    <div
      class="modal-content bg-white rounded-lg shadow-lg p-4 relative w-full md:w-96 animate-fade-in"
    >
      <p class="mb-2 flex items-center">
        <i
          class="fas fa-check text-yellow-500 text-2xl mr-2 animate-bounce"
        ></i>
        <span class="font-semibold text-green-700">Successfully booked</span>
      </p>

      <p class="mb-2 text-left">
        Booking Time:
        <span class="text-green-600 font-semibold">
          <i class="fas fa-calendar-alt"></i> {{ bookingTime }}
        </span>
      </p>

      <p class="mb-2 text-left">
        Expiration Time:
        <span class="text-red-600 font-semibold">
          <i class="fas fa-clock"></i> {{ expiretime }}
        </span>
      </p>

      <button
        class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        @click="offpopup"
      >
        Close
        <i class="fas fa-times ml-2"></i>
      </button>
    </div>
  </div>

  <div class="overlaycup" v-if="isLoading">
    <div class="preloader mt-10"></div>
  </div>

  <div v-if="ispayment" class="overlaycup">
    <div
      class="text-white text-sm w-screen z-50 h-screen bg-black flex flex-col justify-center items-center p-4 rounded-lg shadow-lg"
    >
      <div class="flex items-center">
        <div
          class="w-12 h-12 rounded-full border border-white border-opacity-50"
        >
          <!-- Spinner Container -->
          <div
            class="spinner border-t-2 border-blue-500 h-8 w-8 animate-spin"
          ></div>
        </div>
        <div class="ml-4">
          <p class="font-bold">Please wait while we process your payment</p>
          <p class="text-gray-300">Check your phone number</p>
          <button
            @click="checkcancel"
            class="bg-indigo-700 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md shadow-md mt-2 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10"></div>

  <div>
    <div class="overlay fixed inset-0 flex items-center justify-center mt-30 mb-30 z-10" v-if="showLipanambaModel">
      <img
        src="./../assets/lipanamba.png"
        alt=""
        srcset=""
        style="width: 400px; height: auto"
        @click="showLipanambaModel = false"
      />
      <!-- Close button -->
    </div>
    <!-- Your other content goes here -->
  </div>
  <Footer></Footer>
</template>

<script>
import axios from "axios";
import Footer from "../components/Footer-Component.vue";
import Nav from "../components/Navbar-component.vue";
import bookingCard from "../components/Booking-card";
import PaymentDetail from "./seccessifullbooking_component.vue";

export default {
  components: {
    bookingCard,
    PaymentDetail,
    Nav,
    Footer,
  },
  data() {
    return {
      checkPaymentStatusInterval: null, // Interval reference
      isLoading: false,
      ispayment: false,
      showLipanambaModel: false,
      gFirstName: "",
      gLastName: "",
      hotelprice: "",
      tanzanianRegions: [
        "Arusha",
        "Dar es Salaam",
        "Dodoma",
        "Geita",
        "Iringa",
        "Kagera",
        "Katavi",
        "Kigoma",
        "Kilimanjaro",
        "Lindi",
        "Manyara",
        "Mara",
        "Mbeya",
        "Morogoro",
        "Mtwara",
        "Mwanza",
        "Njombe",
        "Pemba North",
        "Pemba South",
        "Pwani",
        "Rukwa",
        "Ruvuma",
        "Shinyanga",
        "Simiyu",
        "Singida",
        "Tabora",
        "Tanga",
        "Zanzibar North",
        "Zanzibar South",
        "Zanzibar West",
      ],
      gCity: "",
      gState: "hide",
      gZipCode: "100",
      paymentphonenumber: "", // Initialize paymentphonenumber
      isValidPhoneNumber: true,
      gCountry: "Tanzania",
      gHomePhoneNumber: "",
      phoneValidationMessage: "",
      gEmail: "",
      showpaymentmodel: false,
      showdatamodel: false,
      ifsecessifuly: false,
      editing: false,
      hotelid: "", // Replace with a valid hotel ID
      checkinDate: "", // Replace with a valid date
      checkoutDate: "", // Replace with a valid date
      selectedAdults: "", // Replace with the number of selected adults
      selectedChildren: "", // Replace with the number of selected children
      selectedInfants: "", // Replace with the number of selected infants
      expiretime: "", // Replace with a valid expiration time
      bookingTime: "", // Replace with a valid booking time
      hotelcity: "", // Replace with the name of the hotel's city
      userdata: {},
      hotelData: {},
    };
  },
  computed: {
    isCityValid() {
      return this.tanzanianRegions.includes(this.gCity);
    },
    isFormValid() {
      return (
        this.gFirstName.length > 0 &&
        this.gLastName.length > 0 &&
        this.isCityValid &&
        this.isValidPhoneNumber &&
        this.gEmail.includes("@")
      );
    },
  },
  methods: {
    validatePhoneNumberi() {
      // Remove any non-numeric characters
      this.gHomePhoneNumber = this.gHomePhoneNumber.replace(/[^0-9]/g, "");

      // Check if the input doesn't exceed 10 digits
      if (this.gHomePhoneNumber.length > 10) {
        this.gHomePhoneNumber = this.gHomePhoneNumber.slice(0, 10); // Truncate to 10 digits
      }

      // Update the input field with the sanitized value
      this.$forceUpdate();

      if (this.gHomePhoneNumber.length !== 10) {
        this.phoneValidationMessage = "Phone number must be exactly 10 digits.";
      } else {
        this.phoneValidationMessage = "";
      }
    },
    validatePhoneNumber() {
      // Regular expression to validate phone numbers (adjust as needed)
      const phonePattern = /^[0-9]{10}$/; // For a 10-digit phone number

      if (
        this.paymentphonenumber &&
        this.paymentphonenumber.match(phonePattern)
      ) {
        this.isValidPhoneNumber = true;
      } else {
        this.isValidPhoneNumber = false;
      }
    },
    offpopup() {
      this.showdatamodel = !this.showdatamodel;
    },
    showData() {
     this.showLipanambaModel = true;
    },
    
    editData() {
      // Implement any logic you need when the "Editing" button is clicked
    },
    toggleShow() {
      this.editing = !this.editing;
    },
    async bookGuest() {
      if (this.isValidPhoneNumber) {
        const formData = {
          gFirstName: this.gFirstName,
          hotelId: this.hotelid,
          gLastName: this.gLastName,
          gCity: this.gCity,
          gState: this.gState,
          gZipCode: this.gZipCode,
          gCountry: this.gCountry,
          gHomePhoneNumber: this.gHomePhoneNumber,
          gEmail: this.gEmail,
          checkindate: this.$store.state.checkinDate, // Assuming these values are accessible in your JavaScript context
          checkoutdate: this.$store.state.checkoutDate,
          adult: this.$store.state.selectedAdults,
          infant: this.$store.state.selectedInfants,
          child: this.$store.state.selectedChildren,
          paymentphonenumber: this.paymentphonenumber,
          bBookingStatusId: 1, // If this is a fixed value, you csan set it here
        };
        try {
          const response = await axios.post(
            "https://api.dormi.co.tz/api/create-guest",
            formData
          );

          // Assuming the server responds with a successful status code (e.g., 200 or 201).
          if (response.status === 200 || response.status === 201) {
            this.userdata = response.data;
            //put this.userdata in store
            this.$store.commit("setBookingDetail", this.userdata);
            this.ispayment = true;
          } else {
            // Handle unexpected status codes here, if needed.
            alert("Invalid Phone number");
          }
        } catch (error) {
          // Handle request errors (e.g., network issues) and response errors (e.g., invalid JSON).
          alert("Invalid Phone number");
          this.paymentphonenumber = "";

          // You can also set an error flag or message here if needed.
          // this.errorMessage = "An error occurred while making the request.";
        }
      }
    },
    showPaymentModel() {
      if (this.isFormValid) {
        this.showpaymentmodel = true; // Show the payment model overlay
      } else {
        console.log("please insert all data");
      }
    },
    checkcancel() {
      this.ispayment = false;
      this.showpaymentmodel = true;
      this.ifsecessifuly = false;
    },
    paymentnow() {},

    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    getTomorrowDate() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const year = tomorrow.getFullYear();
      const month = tomorrow.getMonth() + 1;
      const day = tomorrow.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },

    validateEmail() {
      // Regular expression to validate email format
      const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

      if (this.gEmail.match(emailPattern)) {
        // Email is valid
        this.emailValidationMessage = "";
        return true;
      } else {
        // Email is not valid
        this.emailValidationMessage = "Invalid email format";
        return false;
      }
    },

    async checkpaymentfromapi() {
      const formData = {
        reference: this.userdata.boookigreference,
      };
      const response = await axios.post(
        "https://api.dormi.co.tz/api/checkstatusforuser",
        formData
      );
      if (response.data == 2) {
        this.ispayment = false;
        this.showpaymentmodel = true;
        this.ifsecessifuly = true;
      }
    },
  },

  mounted() {
    this.checkPaymentStatusInterval = setInterval(() => {
      if (this.ispayment) {
        this.checkpaymentfromapi(); // Call your desired function here
      }
    }, 1000);

    // Call every 1 second (1000 milliseconds)

    // Access the query parameters here
    this.hotelData = this.$store.state.BookingHotelPage;
    this.hotelid = this.$store.state.Hotelid;
    this.checkinDate = this.$store.state.checkinDate;
    this.checkoutDate = this.$store.state.checkoutDate;
    this.selectedAdults = this.$store.state.selectedAdults;
    this.selectedChildren = this.$store.state.selectedChildren;
    this.selectedInfants = this.$store.state.selectedInfants;

    if (this.checkinDate == null || this.checkoutDate == null) {
      this.$store.commit("setCheckinDate", this.getTodayDate());
      this.$store.commit("setCheckoutDate", this.getTomorrowDate());
    }

    const checkinDate = new Date(this.checkinDate);
    const checkoutDate = new Date(this.checkoutDate);

    // Calculate the time difference in milliseconds
    const timeDifference = checkoutDate - checkinDate;
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    console.log(daysDifference);
    const totalPrice = daysDifference * this.hotelData.hotel.price;
    this.hotelprice = totalPrice.toLocaleString();
  },

  beforeUnmount() {
    clearInterval(this.checkPaymentStatusInterval);
  },

  isFormValid() {
    // Check if all required fields are not empty
    const isFirstNameValid = this.gFirstName.trim() !== "";
    const isLastNameValid = this.gLastName.trim() !== "";
    const isCityValid = this.gCity.trim() !== "";
    const isStateValid = this.gState.trim() !== "";
    const isZipCodeValid = this.gZipCode.trim() !== "";
    const isCountryValid = this.gCountry.trim() !== "";
    const isPhoneValid =
      this.gHomePhoneNumber.length === 10 && this.phoneValidationMessage === "";
    const isEmailValid = this.validateEmail();
    return (
      isFirstNameValid &&
      isLastNameValid &&
      isCityValid &&
      isStateValid &&
      isZipCodeValid &&
      isCountryValid &&
      isPhoneValid &&
      isEmailValid
    );
  },
};
</script>

<style scoped>
.containerInfo {
  position: absolute;
  top: 0;
}

/* Your other CSS styles here */

.bgimage img {
  height: 610px;
}

.forminfo {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

input {
  border: 1px solid silver;
  width: 100%;
  height: 38px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.Totalcost {
  width: 100%;
  padding-top: 10px;
}

.bediting {
  background-color: #9b31cf;
  width: 100%;
  max-width: 120px;
  border-radius: 10px;
}

.bookingbtn {
  background-color: rgb(129, 129, 36);
  width: 100%;
  max-width: 120px;
  border-radius: 10px;
}

@media screen and (min-width: 768px) {
  .containerInfo {
    width: 80%;
    left: 10%;
  }
}
.bookingcard {
  position: absolute;
  z-index: 2;

  position: fixed;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77);
  top: 0;
  z-index: 2;
  position: fixed;
}
@keyframes textAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animated-text {
  animation: textAnimation 2s infinite;
}
.error-message {
  color: red;
}

.preloader {
  width: 100px;
  height: 100px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  background-color: white;
  padding: 40px;
  border-radius: 100px;
  z-index: 3;
}
.card {
  width: 300px;
}

.overlaycup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Styling for Validation Messages */
.info-message,
.success-message,
.error-message {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 16px;
}

.info-message {
  background-color: #f0f0f0;
  color: #333;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

/* Icon Styles */
i {
  margin-right: 8px;
  font-size: 20px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-right: 12px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
