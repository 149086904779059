import { createStore } from "vuex";
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import { Date } from "core-js";

export default createStore({
  state: {
    //by default make checkin data be today and checkout be tomorro
    checkinDate: null,
    checkoutDate:null,
    selectedAdults: 1,
    selectedChildren: 0,
    selectedInfants: 0,
    checkinError: '',
    hoteldeleted:false,
    checkoutError: '',
    loggedIn: false,
    token: null,
    role: '',
    name: '',
    searchResults: null,
    gFirstName: "",
    gLastName: "",
    gCity: "",
    gState: "",
    isloading: true,
    gZipCode: "",
    gCountry: "",
    gHomePhoneNumber: "",
    gEmail: "",
    Hotelid: "",
    manager:{},
    managerProved : true,
    hotelData: {},
    BookingHotelPage:{},
    AdminHotels:[],
    selectedItem:'HOME',
    AdminViewHotelData:[],
    refresh:false,
    ServiceUploaded:false,
    Seerror:false,
    bookingdata: {},
  },
  mutations: {
    HotelsInformation(state,hotelview)
    {
      state.AdminViewHotelData = hotelview;
    },
    setSelectedItem(state, item) {
      state.selectedItem = item;
    },
    SET_HOTELS(state, AdminHotels) {
      state.AdminHotels = AdminHotels;
    },
    setHotels(state, hotels) {
      state.hotelforAdmin = hotels;
    },
    
    SET_SEARCH_RESULTS(state, data) {
      state.searchResults = data;
    },
    clearCheckinError(state) {
      state.checkinError = "";
    },
    clearCheckoutError(state) {
      state.checkoutDate = "";
    },
    setCheckoutDate(state, newDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

      // Create a Date object from the newDate parameter
      const selectedDate = new Date(newDate);
      selectedDate.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

      // Compare the newDate with today's date
      if (selectedDate > today) {
        // If the newDate is before today, set the error message in state
        state.checkoutDate = newDate;
      } else {
        // Otherwise, use the provided newDate and clear any previous error message
        state.checkinError = 'Please insert the correct checkout date';
      }
    },
    setCheckinDate(state, newDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const selectedDate = new Date(newDate);
      selectedDate.setHours(0, 0, 0, 0);
      if (selectedDate < today) {
        // If the newDate is before today, show an error message
        state.checkinError = 'Please insert the correct checkin date';
      } else {
        // Otherwise, use the provided newDate
        state.checkinDate = newDate;
      }

    },
    BOOKINGHOTELINFO(state, hotelData) {
      state.BookingHotelPage = hotelData;
    },
    setHotel(state, hotelData) {
      state.hotel = hotelData;
      state.loading = false;
    },
    setOuserService(state, userData) {
      state.ouserservice = userData;
      state.loading = false;
    },
    setGuestData(state, { key, value }) {
      state.guestData[key] = value;
    },
    bookGuest(state, formData) {
      state.gFirstName = formData.gFirstName
      state.gLastName = formData.gLastName
      state.gCity = formData.gCity
      state.gState = formData.gState
      state.gZipCode = formData.gZipCode
      state.gCountry = formData.gCountry
      state.gHomePhoneNumber = formData.gHomePhoneNumber
      state.gEmmanagerail = formData.gEmail
    },
    incrementQuantity(state, category) {
      if (category === 'adults') {
        state.selectedAdults++;
      } else if (category === 'children') {
        state.selectedChildren++;
      } else if (category === 'infants') {
        state.selectedInfants++;
      }
      // Add additional logic for other categories if needed
    },
    decrementQuantity(state, category) {
      if (category === 'adults' && state.selectedAdults > 1) {
        state.selectedAdults--;
      } else if (category === 'children' && state.selectedChildren > 0) {
        state.selectedChildren--;
      } else if (category === 'infants' && state.selectedInfants > 0) {
        state.selectedInfants--;
      }
      // Add additional logic for other categories if needed
    },

    SET_LOGGED_IN(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_ROLE(state, role) {
      state.role = role; // Mutation to set the user role
    },
    SET_NAME(state, name) {
      state.name = name; // Mutation to set the 'name' property in the Vuex state
    },
    HotelDataId(state, hotelid) {
      state.Hotelid = hotelid;
    },
    RESET_STATE(state) {
      state.checkinDate = null;
      state.checkoutDate = null;
      state.selectedAdults = 1;
      state.selectedChildren = 0;
      state.selectedInfants = 0;
      state.checkinError = '';
      state.checkoutError = '';
      state.loggedIn = false;
      state.token = null;
      state.role = '';
      state.name = null;
    },
    HOTELDELETED(state,hoteldeleted)
    {
      state.hoteldeleted = hoteldeleted;
    },
    // this.$store.commit("setBookingDetail", response.data);
    setBookingDetail(state, bookingDetail) {
      state.bookingdata = bookingDetail;
    },
  

    REFRESH(state,refresh)
    {
      state.refresh = refresh;
    },
    ServiceAded(state,ServiceAded)
    {
      state.ServiceUploaded = ServiceAded;
      state.managerProved   = true;
    },
    ServiceError(state,errordata)
    {
      state.Seerror = errordata;
    },
    SET_MANAGER(state,manager)
    {
      state.manager = manager;
    }

   

    
  },

  actions: {

 
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('  https://api.dormi.co.tz/api/login', credentials);
        const { token, role, name} = response.data;

        if (role === 'manager') {
          const { token, role, name,manager} = response.data;
          commit('SET_LOGGED_IN', true);
          commit('SET_TOKEN', token);
          commit('SET_ROLE', role);
          commit('SET_NAME', name);
          commit('SET_MANAGER',manager);
    

        }

        // Update the Vuex state with the received token
        commit('SET_LOGGED_IN', true);
        commit('SET_TOKEN', token);
        commit('SET_ROLE', role);
        commit('SET_NAME', name);
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },


   async InsertpictureId({commit,state},photoid)
    {
         commit('HOTELDELETED',true);
         const response = await axios.post('  https://api.dormi.co.tz/api/deletephoto', {photoid});
         const photoIndexToDelete = state.AdminViewHotelData.photos.findIndex(photo => photo.id === photoid);
         if (photoIndexToDelete !== -1) {
          state.AdminViewHotelData.photos.splice(photoIndexToDelete, 1);
          
      }

         console.log(response);
    },

    async InsertManager({commit},manager)
    {
      try {
        commit('ServiceAded', true);
        const response = await axios.post('https://api.dormi.co.tz/api/manager', manager);
    
        // Check if the status code indicates success (e.g., 200 or 201)
        if (response.status >= 200 && response.status < 300) {
          // Show a success message in an alert
          alert('Manager inserted successfully');
        } else {
          // Handle other status codes or errors as needed
          alert('An error occurred while inserting the manager');
        }
      } catch (error) {
        // Handle request errors (e.g., network issues) and response errors (e.g., invalid JSON).
        alert('An error occurred while making the request');
      }
    },
    async sendingServiceInfo({commit},data)
    {
      commit('ServiceAded', true);
      console.log('nimejalibu iko poa' + data);
      const response = await axios.post('  https://api.dormi.co.tz/api/hoteldescription', data);
      console.log(response.json);
    },

    async ServiceOfhotel({ commit }, hotelservice) {
      try {
        console.log(hotelservice);
        const response = await axios.post('  https://api.dormi.co.tz/api/storeservice', hotelservice);
  
          console.log(response);
          commit('ServiceAded', true);
          commit('ServiceError', false);
          
       
      } catch (error) {
        console.error('Error while adding service:', error);
        commit('ServiceError', true);
        commit('ServiceAded', false);
      }
    },

  async DELETEHOTEL({commit},data)
  {
   commit('HOTELDELETED',true);
   const response = await fetch(`  https://api.dormi.co.tz/api/deletehotel/${data}`);
   const dataobject    = await response.json();
   console.log(dataobject);
   const refresh = true;
   
   commit('REFRESH',refresh);
  },
  async  UPDATEVIEWHOTELID({commit},hotelid)
    {
     
      try {
        // Replace this with your actual API call to fetch user data based on the userId
        const response = await fetch(`  https://api.dormi.co.tz/api/alldata/${hotelid}`);
        const hotelData = await response.json();
        commit('HotelsInformation', hotelData);
      

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    selectItem({ commit }, item) {
      commit('setSelectedItem', item);
    },
    setHotels({ commit }, AdminHotels) {
      commit('SET_HOTELS', AdminHotels);
    },

    async fetchHotelData({ commit }, hotelId) {
      try {
        // Replace this with your actual API call to fetch user data based on the userId
        const response = await fetch(`  https://api.dormi.co.tz/api/alldata/${hotelId}`);
        const hotelData = await response.json();
        commit('BOOKINGHOTELINFO', hotelData);
        commit('HotelDataId', hotelId);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },

    logout({ commit }) {
      // Clear the authentication data and reset the state
      commit('SET_LOGGED_IN', false);
      commit('SET_TOKEN', null);
      commit('SET_ROLE', '');
      commit('SET_NAME', null);
      // Reset the state to initial values
      commit('RESET_STATE');
    },


   
  },

  getters: {
    getSearchResults: (state) => state.searchResults,
    getHotels(state) {
      state.isloading = false;
      return state.AdminHotels;
    },
    getCurrentItem: state => {
      return state.currentItem;
    },
    getServiceupoaded: state => state.ServiceUploaded,

    errordata:state => state.Seerror
  
  },



  plugins: [
    createPersistedState({
      key: 'my-vuex-state', // Set a unique identifier for the persisted state
      paths: ['token', 'loggedIn', 'name', 'hotelData', 'hotelid', 'checkinDate', 'checkoutDate', 'selectedChildren', 'selectedInfants', 'selectedAdults','hotelforAdmin','selectedItem','AdminViewHotelData','role','manager','bookingdata'], // Specify the data properties to persist (token and loggedIn)
    }),
  ],

})