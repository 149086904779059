<template>
  <div v-if="loading" class="overlay">
    <!-- Add your preloader HTML/CSS here -->
    <div class="spinner"></div>
  </div>
  <div class="flex flex-col md:flex-row lg:flex-row gap-6 px-10 py-8">
    <div
      class="flex flex-col shadow-md justify-center md:w-1/4 items-center gap-2 border border-dashed border-gray-500 p-4 rounded-md h-32"
      v-for="hotel in hotelinfo.hotelinfo"
      :key="hotel.key"
    >
      <div class="flex gap-2 items-center cursor-pointer" @click="manageroom">
        <span class="font-bold text-4xl">{{ hotel.totalroom }}</span>
        <svg
          class="w-8 h-8 opacity-70"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
          ></path>
        </svg>
      </div>
      <span class="font-semibold opacity-70 text-sm text-center"
        >Total Rooms</span
      >
    </div>

    <div
      class="flex flex-col shadow-md md:w-1/4 justify-center items-center gap-2 border border-dashed border-gray-500 p-4 rounded-md h-32"
    >
      <div class="flex gap-2 items-center">
        <span
          v-if="parseFloat(hotelinfo.price) >= 0"
          class="font-bold text-4xl text-yellow-500"
        >
          {{ parseFloat(hotelinfo.price).toLocaleString() }}
        </span>
        <span
          v-if="parseFloat(hotelinfo.price) == 0"
          class="text-red-500 font-bold"
        >
          No pending price
        </span>
        <div class="animate-bounce text-yellow-500">
          <i class="fas fa-exclamation-circle text-xl"></i>
        </div>
      </div>
      <span class="font-semibold opacity-70 text-sm text-center"
        >Pending Price</span
      >
    </div>

    <div
      class="flex flex-col md:w-1/4 shadow-md justify-center items-center gap-2 border border-dashed border-gray-500 p-4 rounded-md h-32"
      v-for="hotel in hotelinfo.hotelinfo"
      :key="hotel.key"
    >
      <div class="flex gap-2 items-center">
        <span class="font-bold text-4xl">{{
          hotel.price.toLocaleString()
        }}</span>
      </div>
      <span class="font-semibold opacity-70 text-sm text-center"
        >price for 1 night</span
      >
    </div>

    <div
      class="md:col-start-2 shadow-md lg:col-auto flex flex-col justify-center items-center gap-2 border border-dashed border-gray-500 p-4 rounded-md h-32"
    >
      <div class="flex gap-2 items-center">
        <span class="font-bold text-4xl">
          {{ total.toLocaleString() }}
        </span>
      </div>
      <span class="font-semibold opacity-70 text-sm text-center"
        >Total Prices</span
      >
    </div>
  </div>
  <div class="overflow-x-auto">
    <div class="grid grid-flow-row place-items-center bg-slate-100 p-2">
      <!-- Search Bar -->
      <div class="mb-4">
        <div class="relative">
          <input
            type="text"
            placeholder="Search..."
            class="px-4 py-2 border border-gray-300 rounded-full w-full md:w-64 focus:outline-none focus:ring focus:border-blue-500"
            v-model="username"
          />
          <button
            class="absolute right-0 top-0 mt-2 mr-2 px-4 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300"
            @click="user"
          >
            Search
          </button>
        </div>
      </div>
    </div>

    <div
      class="container bg-slate-400 shadow-lg rounded-sm"
      v-if="showsearchedata == true"
    >
      <div class="flex md:flex-row flex-col p-5 gap-2">
        <div
          class="container md:w-1/2 sm:w-full bg-white shadow-lg text-start rounded-sm p-3"
        >
          <h3 class="text-center md:text-start mt-2 text-shadow-md">
            User Information
          </h3>
          <hr />
          <div class="" v-if="searcheddata">
            <div v-for="guest in searcheddata.guest" :key="guest.id">
              <p>
                <i class="fas fa-user"></i> {{ guest.gFirstName }}
                {{ guest.gLastName }}
              </p>
              <p>
                <i class="fas fa-map-marker-alt"></i> {{ guest.gCity }},
                {{ guest.gState }}
              </p>
              <p
                class="status bg-gray-900 text-white rounded-sm shadow-md p-3 mt-1"
              >
                <i class="fas fa-phone"></i> {{ guest.gHomePhoneNumber }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="container md:w-1/2 sm:w-full bg-white shadow-lg text-start sm:mt-1 rounded-sm p-3"
        >
          <h3 class="text-center md:text-start mt-2 text-shadow-md">
            Payments Information
          </h3>

          <hr />
          <div class="" v-if="searcheddata">
            <div v-for="payment in searcheddata.payment" :key="payment.id">
              <p>
                <i class="fas fa-credit-card"></i> {{ payment.paymentmethod }}
              </p>
              <p><i class="fas fa-phone"></i> {{ payment.phone_number }}</p>
              <p>
                <i class="fas fa-receipt"></i> {{ payment.reference_number }}
              </p>
              <p>
                <i class="fas fa-dollar-sign"></i>
                {{ payment.Totalprice.toLocaleString() }}
              </p>
              <p
                class="status bg-gray-900 text-white rounded-sm shadow-md p-3 mt-1"
              >
                <i class="fas fa-check"></i>
                {{ payment.status == 1 ? "pending" : "paid" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

 

    <table
      class="min-w-full divide-y divide-gray-200"
      v-if="showsearchedata == false"
    >
      <thead class="bg-gray-50 mx-4">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden md:table-cell"
          >
            Fname
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Phone
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Payment methode
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Total price
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Date From
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Date To
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            status
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200" v-if="totalBooking">
        <tr v-for="booking in paginatedData" :key="booking.id">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              {{ booking.guest.gFirstName }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              {{ booking.guest.gHomePhoneNumber }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900" v-if="booking.payment">
              <!-- [ { "id": 36, "status": "1", "paymentmethod": "Halo-pesa", "guest_id": 344, "phone_number": "0622080947", "booking_id": 209, "reference_number": 81312, "created_at": "2023-09-11T06:37:42.000000Z", "updated_at": "2023-09-11T06:37:42.000000Z", "Totalprice": 0 } ]  -->
              <div v-for="payment in booking.payment" :key="payment.key">
                {{ payment.paymentmethod }}
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900" v-if="booking.payment">
              <!-- [ { "id": 36, "status": "1", "paymentmethod": "Halo-pesa", "guest_id": 344, "phone_number": "0622080947", "booking_id": 209, "reference_number": 81312, "created_at": "2023-09-11T06:37:42.000000Z", "updated_at": "2023-09-11T06:37:42.000000Z", "Totalprice": 0 } ]  -->
              <div v-for="payment in booking.payment" :key="payment.key">
                {{ payment.Totalprice.toLocaleString() }}
              </div>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              {{ booking.guest.booking[0].bDateFrom }}
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              {{ booking.guest.booking[0].bDateTo }}
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            <div class="text-sm text-gray-900" v-if="booking.payment">
              <div v-for="payment in booking.payment" :key="payment.key">
                <button
                    v-if="payment.status == 0"
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 shadow-md"
                  >
                    <i class="fas fa-times mr-2"></i>Fail
                  </button>

              
                  <button
                    v-if="payment.status == 2"
                    class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 shadow-md"
                  >
                    <i class="fas fa-clock mr-2"></i>Panding
                  </button>

                  <button
                    v-else-if="payment.status == 3"
                    class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition transform hover:scale-105 shadow-md"
                  >
                    <i class="fas fa-check mr-2"></i>verfied
                  </button>
               
              </div>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
            <div class="flex space-x-4">
              <a
                href="#"
                class="text-indigo-600 hover:text-indigo-900 transition-colors duration-300 ease-in-out transform hover:scale-105 flex items-center"
                @click="showuser(booking.guest.id)"
              >
                <i class="fas fa-eye mr-1"></i>
              </a>
              <a
                href="#"
                class="text-red-600 hover:text-red-900 transition-colors duration-300 ease-in-out transform hover:scale-105 flex items-center"
                @click="deleteuser(booking.guest.id)"
              >
                <i class="fas fa-trash-alt mr-1"></i>
              </a>
            </div>
          </td>
        </tr>
        <!-- Add more table rows as needed -->
      </tbody>
    </table>
    <div class="pagination flex items-center justify-center mt-4 w-full">
    <button @click="previousPage" :disabled="currentPage === 1" class="px-4 py-2 rounded-l-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        Previous
    </button>
    <span class="px-4 py-2 bg-gray-300 text-gray-700">
        {{ currentPage }}
    </span>
    <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 rounded-r-md bg-gray-300 text-gray-600 hover:bg-gray-400 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed">
        Next
    </button>
</div>
    
    <table v-if="showusersearch"></table>
  </div>
  <div
    v-if="manageroomnumber"
    class="fixed inset-0 flex items-center justify-center overlaycup"
  >
    <form
      action=""
      class="bg-white p-4 shadow-md"
      @submit.prevent="addingnumber"
    >
      <div class="mb-4">
        <label for="room" class="block text-gray-700 font-bold"
          >Room number</label
        >
        <input
          type="number"
          class="w-full px-3 py-2 border rounded-md"
          id="room"
          v-model="room"
        />
      </div>
      <div>
        <button type="submit" class="btn btn-primary flex items-center">
          <span class="mr-2" v-if="!ok">Submit</span>
          <span v-if="ok" class="bg-emerald-400 px-2 py-1 rounded-full">
            Room updated
          </span>
          <span
            v-if="ok"
            class="bg-emerald-400 px-2 py-1 ml-2 rounded-full cursor-pointer"
            @click="closehere"
          >
            Close
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script >
import axios from "axios";
import store from "../../store/index";

export default {
  data() {
    return {
      manager: {},
      hotelinfo: {},
      hotelid: "",
      username: "",
      itemsPerPage: 5,   // Number of items to display per page
      currentPage: 1,     // Current page
      manageroomnumber: false,
      showuserinfo: false,
      room: "",
      total: "",
      loading: false,
      ok: false,
      totalBooking: [],
      searcheddata: "",
      showsearchedata: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalBooking.length / this.itemsPerPage);
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.totalBooking.slice(start, end);
    },
  },

  mounted() {
    this.manager = store.state.manager;
    this.hotelid = this.manager.hotelid;
    this.fetchHotels();
    this.fearchbooking();
  },

  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async user() {
      this.loading = true;
      try {
        const userdata = {
          username: this.username,
          hotelid: this.hotelid,
        };

        const response = await axios.post(
          "https://api.dormi.co.tz/api/finduser",
          userdata
        );

        if (response.data == "error") {
          alert("Invalid reference number");
          this.username = "";
        } else {
          this.searcheddata = response.data;
          this.showsearchedata = true;
          this.username = "";
        }
      } catch (error) {
        console.error("Error in user function:", error);
      } finally {
        this.loading = false;
      }
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    async deleteuser(userid) {
      try {
        const sure = confirm("Are you sure you want to delete this user?");

        const userinfo = {
          userid: userid,
        };

        if (sure) {
          const response = await axios.post(
            "https://api.dormi.co.tz/api/deleteuser",
            userinfo
          );
          console.log(response.data);
          if (response) {
            this.fearchbooking();
          }
        }
      } catch (error) {
        console.error("Error in deleteuser function:", error);
      }
    },

    showuser(userid) {
      this.showguestinfo = true;
      this.$router.push({ name: "guesting", params: { userid: userid } });
    },

    async fetchHotels() {
      try {
        const forminfo = {
          hotelid: this.hotelid,
        };

        const response = await axios.post(
          "https://api.dormi.co.tz/api/hotelinfomationformanager",
          forminfo
        );
        this.hotelinfo = response.data;
        console.log(this.hotelinfo);
      } catch (error) {
        console.error("Error in fetchHotels function:", error);
        this.loading = false;
      }
    },

    manageroom() {
      this.manageroomnumber = true;
    },

    async addingnumber() {
      try {
        const formidata = {
          hotelid: this.hotelid,
          room: this.room,
        };
        const response = await axios.post(
          "https://api.dormi.co.tz/api/manageroomforhotel",
          formidata
        );
        console.log(response);
        if (response) {
          this.ok = true;
          this.fetchHotels();
        }
      } catch (error) {
        console.error("Error in addingnumber function:", error);
      }
    },

    closehere() {
      this.manageroomnumber = false;
      this.ok = false;
    },

    async fearchbooking() {
      try {
        const forminfo = {
          hotelid: this.hotelid,
        };

        const response = await axios.post(
          "https://api.dormi.co.tz/api/feachingorderbooking",
          forminfo
        );
        this.totalBooking = response.data["guestinfo"];
        this.total = response.data["total"];

        console.log(this.total);
      } catch (error) {
        console.error("Error in fearchbooking function:", error);
      }
    },
  },
};
</script>


<style scoped>
.overlaycup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pagination-container {
  display: flex;
  column-gap: 10px;
}
.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>



