<template>
  <div class="max-w-md mx-auto p-6 bg-white rounded shadow-md">
    <form
      @submit.prevent="submitForm"
      enctype="multipart/form-data"
      v-if="hotelData.hotel"
    >
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-600"
          >Hotel Name:</label
        >
        <input
          v-model="hotelData.hotel.name"
          type="text"
          id="name"
          name="name"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="totalroom" class="block text-sm font-medium text-gray-600"
          >Total Rooms:</label
        >
        <input
          v-model="hotelData.hotel.totalroom"
          type="number"
          id="totalroom"
          name="totalroom"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="price" class="block text-sm font-medium text-gray-600"
          >Price:</label
        >
        <input
          v-model="hotelData.hotel.price"
          type="number"
          id="price"
          name="price"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="hmoto" class="block text-sm font-medium text-gray-600"
          >Motto:</label
        >
        <input
          v-model="hotelData.hotel.hmoto"
          type="text"
          id="hmoto"
          name="hmoto"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="hadress" class="block text-sm font-medium text-gray-600"
          >Address:</label
        >
        <input
          v-model="hotelData.hotel.hadress"
          type="text"
          id="hadress"
          name="hadress"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>
      <div class="mb-4">
        <label for="hAdress2" class="block text-sm font-medium text-gray-600"
          >Address 2:</label
        >
        <input
          v-model="hotelData.hotel.hAdress2"
          type="text"
          id="hAdress2"
          name="hAdress2"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label
          for="hmainPhoneNumber"
          class="block text-sm font-medium text-gray-600"
          >Phone Number:</label
        >
        <input
          v-model="hotelData.hotel.hmainPhonenumber"
          type="text"
          id="hmainPhoneNumber"
          name="hmainPhoneNumber"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="latitude" class="block text-sm font-medium text-gray-600"
          >Latitude:</label
        >
        <input
          v-model="hotelData.hotel.latitude"
          type="text"
          id="latitude"
          name="latitude"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="longitude" class="block text-sm font-medium text-gray-600"
          >Longitude:</label
        >
        <input
          v-model="hotelData.hotel.longitude"
          type="text"
          id="longitude"
          name="longitude"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="hcity" class="block text-sm font-medium text-gray-600"
          >City:</label
        >
        <input
          v-model="hotelData.hotel.hcity"
          type="text"
          id="hcity"
          name="hcity"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="hstate" class="block text-sm font-medium text-gray-600"
          >State:</label
        >
        <input
          v-model="hotelData.hotel.hstate"
          type="text"
          id="hstate"
          name="hstate"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <label for="htype" class="block text-sm font-medium text-gray-600"
          >Type:</label
        >
        <input
          v-model="hotelData.hotel.htype"
          type="text"
          id="htype"
          name="htype"
          required
          class="mt-1 p-2 w-full border rounded-md"
        />
      </div>

      <div class="mb-4">
        <button
          type="submit"
          class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
          @click="fetchHotelData"
        >
          update
        </button>
      </div>
    </form>
  </div>
</template>
    
    <script>
// import axios from 'axios'
import axios from "axios";
export default {
  name: "HotelRegistration",
  data() {
    return {
      hotelData: [],
      formData: {
        name: "",
        hmoto: "",
        latitude: "",
        longitude: "",
        hadress: "",
        address2: "",
        hmainPhonenumber: "",
        price: "", // Store the selected image file
        hcity: "",
        hstate: "",
        htype: "",
        totalroom: "",
        hotelid: "",
        hotelId: "",
      },
    };
  },
  computed: {
    index() {
      return this.$route.params.index;
    },
  },
  created() {
    this.hotelId = this.$route.params.index; // Access the hotelId parameter from the route
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(
          `https://api.dormi.co.tz/api/alldata/${this.hotelId}`
        );
        const jsonData = await response.json();
        this.hotelData = jsonData;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async fetchHotelData() {
      this.formData.name = this.hotelData.hotel.name;
      this.formData.hmoto = this.hotelData.hotel.hmoto;
      this.formData.latitude = this.hotelData.hotel.latitude;
      this.formData.longitude = this.hotelData.hotel.longitude;
      this.formData.hcity = this.hotelData.hotel.hcity;
      this.formData.hstate = this.hotelData.hotel.hstate;
      this.formData.htype = this.hotelData.hotel.htype;
      this.formData.hmainPhonenumber = this.hotelData.hotel.hmainPhonenumber;
      this.formData.hadress = this.hotelData.hotel.hadress;
      this.formData.address2 = this.hotelData.hotel.hAdress2;

      this.formData.totalroom = this.hotelData.hotel.totalroom;
      this.formData.price = this.hotelData.hotel.price;
      this.formData.hotelid = this.hotelData.hotel.id;
      console.log(this.formData);

      const response = await axios.post(`https://api.dormi.co.tz/api/updatHotel`,
        this.formData
      );

      if (response) {
        alert("hotel updated successiclly");
      } else {
        console.error(this.formData);
      }
    },
  },
};
</script>

