import { createApp } from 'vue'
import JwPagination from 'jw-vue-pagination';
import store from "./store/index"
import './index.css';
// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

import router from './Router'
import App from './App.vue'

createApp(App).use(router).use(VueAwesomePaginate).use(JwPagination).use(store).mount('#app')
