<template>
  <div class="mx-auto p-8">
    <div class="text-start bg-slate-50 min-w-full rounded-sm shadow-lg">
      <h1 class="text-3xl font-semibold mb-8 text-center p-1 underline">
        Payment Data
      </h1>
    </div>
    <div v-if="isLoading" class="text-center mt-4">
      <div class="animate-spin text-blue-500 text-4xl">
        <i class="fas fa-circle-notch"></i>
      </div>
      <p class="mt-2">Loading...</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" v-else>
      <!-- Loop through the data and display cards -->
      <div v-for="(payments, name) in hoteldata" :key="name">
        <div class="card shadow-2xl">
          <div
            class="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 duration-300 ease-in-out"
          >
            <div class="mb-4 text-xl font-semibold">{{ name }}</div>

            <ul class="space-y-2" style="max-height: 200px">
              <div class="mb-2 text-lg font-semibold">
                {{ payments.total_price }}
              </div>
            </ul>
            <button
              v-if="payments.total_price !== 0"
              @click="confirmPayment(payments.total_price, payments.hotelid)"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <i class="fas fa-check-circle"></i> Confirm
            </button>

            <button
              v-else
              class="bg-red-300 text-gray-500 font-bold py-2 px-4 rounded cursor-not-allowed transition transform hover:scale-105"
              disabled
            >
              <i class="fas fa-exclamation-circle mr-2 animate-bounce"></i> No
              pending payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" v-if="ispayment">
    <div
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <div
        class="bg-black bg-opacity-80 text-white text-sm w-full max-w-sm p-4 rounded-lg shadow-lg"
      >
        <div class="flex items-center mb-4">
          <div
            class="w-12 h-12 rounded-full border border-white border-opacity-50"
          >
            <!-- Spinner Container -->
            <div
              class="spinner border-t-2 border-blue-500 h-12 w-12 animate-spin"
            ></div>
          </div>
          <div class="ml-4">
            <p class="font-bold">Please wait while we process your payment</p>
            <p class="text-gray-300">Check Your phone for Payment</p>
            <button
              @click="checkcancel"
              class="bg-indigo-700 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md shadow-md mt-2 transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-300"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      hoteldata: {},
      isLoading: true,
      ispayment: false,
      total_price: "",
      checkPaymentStatusInterval: null,
      hotelid: "",
    };
  },

  mounted() {
    this.checkPaymentStatusInterval = setInterval(() => {
      if (this.ispayment) {
        this.checkpaymentfromapi(); // Call your desired function here
      }
    }, 1000);

    this.getdata();
  },
  methods: {

    // async checkpaymentfromapi() {
    //   const formData = {
    //     totalprice: this.total_price,
    //     hotelid: this.hotelid,
    //   };

    //   const response = await axios.post(
    //     "https://api.dormi.co.tz/api/adminconfirm",
    //     formData
    //   );
    //   if (response.data == 3) {
    //     alert("payment sucessifully");
    //     this.getdata();
    //   }
    // },

    checkcancel() {
      this.ispayment = false;
    },
    async getdata() {
      setTimeout(async () => {
        const response = await axios.get("https://api.dormi.co.tz/api/testing");
        this.hoteldata = response.data;
        this.isLoading = false;
      }, 3000); // 3000 milliseconds (3 seconds) delay
    },

    async confirmPayment(total_price, hotelid) {

        const formData = {
        totalprice: total_price,
        hotelid: hotelid,
        }
      const response = await axios.post(
        "https://api.dormi.co.tz/api/adminconfirm",
        formData
      );
      console.log(response.data);
    },


  },

  beforeUnmount() {
    clearInterval(this.checkPaymentStatusInterval);
  },
};
</script>

<style>
.overlay {
  width: 100%;
  height: 100%;
  z-index: 9999; /* Set your desired z-index value */
  position: fixed; /* This is often used for overlays to cover the entire viewport */
  top: 0;
  left: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: Add a semi-transparent background color */
}
.spinner {
  width: 2rem; /* Adjust the width and height as needed */
  height: 2rem;
  border: 2px solid transparent;
  border-top: 2px solid #3498db; /* Blue border color */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Create a spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

