<template>
  <!-- for header part -->
  <header class="body">
    <div class="mt-0">
    
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="showmenubarhere"
        height="2em"
        class="sm:hidden"
        viewBox="0 0 448 512"
     
      >  
        <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
        />
      </svg>
      <router-link to="/">
        <img
          src="../asset/png-clipart-hotel-restaurante-cortijo-del-arte-bed-mattress-room-computer-icons-distinguished-guest-mattress-furniture.png"
          class="icn menuicn hidden lg:block"
          id="menuicn"
          alt="menu-icon"
        />
      </router-link>
    </div>
    <div class="searchbar">
      <input
        type="text"
        placeholder="Search"
        v-model="search"
        class="py-2 px-4 bg-gray-200 text-gray-800 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
      />
      <div class="searchbtn" @click="searchinghotel"></div>
    </div>

    <div class="message">
      <div class="circle"></div>

      <div class="dp">
        <img
          src="https://media.geeksforgeeks.org/wp-content/uploads/20221210180014/profile-removebg-preview.png"
          class="dpicn"
          alt="dp"
        />
      </div>
    </div>
  </header>
  <div class="main-container">
    <div class="navcontainer">
      <Sidebar class="sidebar" />
    </div>
    <maincomponent />
  </div>

  

  <div
    class="absolute data top-9 text-start w-full mx-auto bg-white shadow-lg rounded-sm transform transition-all duration-300 ease-in-out"
    v-if="showManue"
  >
    <button
      @click="close"
      class="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
    >
      <svg
        class="h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>

    <div class="flex flex-col gap-4 p-4 justify-center items-center">
      <div class="shadow-sm rounded-md p-4" v-if="customerInformation.guest">
        <div
          class="max-w-md p-6 bg-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
        >
          <p class="text-xl font-semibold text-black">Customer Information</p>
          <div class="mt-4">
            <div class="flex items-center mb-2">
              <!-- Icon for First Name -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-600 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p class="text-gray-700">
                First Name: {{ customerInformation.guest.gFirstName }}
              </p>
            </div>
            <div class="flex items-center mb-2">
              <!-- Icon for Last Name -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-600 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p class="text-gray-700">
                Last Name: {{ customerInformation.guest.gLastName }}
              </p>
            </div>
            <div class="flex items-center mb-2">
              <!-- Icon for Email -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-600 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p class="text-gray-700">
                Email: {{ customerInformation.guest.gEmail }}
              </p>
            </div>
            <div class="flex items-center mb-2">
              <!-- Icon for Address -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-600 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p class="text-gray-700">
                Address: {{ customerInformation.guest.gCountry }},
                {{ customerInformation.guest.gState }}
              </p>
            </div>
            <div class="flex items-center mb-2">
              <!-- Icon for Phone Number -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4 text-gray-600 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
              <p class="text-gray-700">
                Phone Number: {{ customerInformation.guest.gHomePhoneNumber }}
              </p>
            </div>
          </div>
        </div>
        <p
          class=" text-black mt-4 text-center flex items-center justify-center space-x-2 animate-fade-in"
        >
          Payment Details
        </p>
        <div
          class="max-w-md p-6 bg-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
        >
          <!-- Add an icon -->
          <div class="flex items-center mb-4">
            <p class="text-gray-700 flex items-center">
    <i class="fas fa-credit-card text-blue-500 mr-2"></i> Payment Method: {{ customerInformation.paymentdetail.paymentmethod }}
</p>
          </div>
          <p class="text-gray-700">
            <i class="fas fa-phone"></i> Phone Number used:
            {{ customerInformation.paymentdetail.phone_number }}
          </p>
          <p class="text-gray-700">
            <i class="fas fa-hotel"></i>
            {{ customerInformation.hotel.htype }} Name:
            {{ customerInformation.hotel.name }}
          </p>
          <p class="text-gray-700">
            <i class="fas fa-money-bill-wave"></i> Payment cost
            {{ customerInformation.paymentdetail.Totalprice }} 
          </p>
          <!-- <p class="text-gray-700">Status: {{customerInformation.paymentdetail.status}}</p> -->
          <button
            v-if="customerInformation.paymentdetail.status == 2"
            class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg flex items-center space-x-2 transition duration-300"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
            Paid
          </button>
          <button
            v-if="customerInformation.paymentdetail.status == 1"
            class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-lg flex items-center space-x-2 transition duration-300"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 17l-3-3m0 0l3-3m-3 3h14"
              ></path>
            </svg>
            Pending
          </button>
        </div>
      </div>
    </div>
  </div>




  <div class="w-full top-10 text-black absolute z-50 shadow-lg text-start mx-auto rounded-lg bg-white pt-10" v-if="showmenu">
  <router-link to="/homepage" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-chart-bar text-blue-500 mr-2"></i>
    <span>Dashboard</span>
  </router-link>
  <router-link to="/addinghotel" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-hotel text-blue-500 mr-2"></i>
    <span>Add Hosting</span>
  </router-link>
  <button to="/bookedhotel" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-book text-blue-500 mr-2"></i>
    <span>Logiout</span>
  </button>
  <router-link to="/bookedhotel" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-book text-blue-500 mr-2"></i>
    <span>Booking</span>
  </router-link>
  <router-link to="/managerDetail" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-user-tie text-blue-500 mr-2"></i>
    <span>Manager Detail</span>
  </router-link>
  <router-link to="/manageHotelPayment" class="flex items-center p-2 hover:bg-gray-100" @click="hidemenue">
    <i class="fas fa-money-bill-wave text-blue-500 mr-2"></i>
    <span>Payment</span>
  </router-link>
  <button class="flex items-center p-2 hover:bg-gray-100" @click="logout">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-5 w-5 text-blue-500 mr-2"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      d="M2 3a1 1 0 011-1h8a1 1 0 110 2H3a1 1 0 01-1-1zm7 9a1 1 0 01-1 1H3a1 1 0 010-2h5a1 1 0 011 1zm4-4a1 1 0 00-1-1H5a1 1 0 100 2h7a1 1 0 001-1z"
    />
    <path
      fill-rule="evenodd"
      d="M11 4a1 1 0 00-1 1v8a1 1 0 102 0V5a1 1 0 00-1-1z"
    />
  </svg>
  <span>Logout</span>
</button>
</div>
</template>

<script>
import { mapActions } from "vuex";
import store from "../../store";
import Sidebar from "../components/sidebar-component.vue";
import maincomponent from "../components/Main-component.vue";
import axios from "axios";
export default {
  data() {
    return {
      changedata: false,
      selectedItem: "dashboard",
      menubaricon: false,
      showmenu: false,
      search: "",
      customerInformation: {},
      showManue: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      //redirect to /
      this.$router.push("/");
    },
    close() {
      this.showManue = false;
      this.search = "";
    },
    hidemenue() {
      this.showmenu = false;
    },

    async searchinghotel() {
      const userreference = {
        user_reference: this.search,
      };
      const response = await axios.post(
        " https://api.dormi.co.tz/api/getUserbyreference",
        userreference
      );
      if (response.data == "error") {
        alert("No searching data not fount");
      } else {
        this.customerInformation = response.data;
        this.showManue = true;
      }
    },
    showmenubarhere() {
      this.showmenu = !this.showmenu;

      console.log("welcome"); // Toggle the showmenu variable
    },
    navchange() {
      this.isNavClosed = !this.isNavClosed;
    },
    changeMainContent(item) {
      this.selectedItem = item;
    },
    ...mapActions(["setHotels"]), // Map the setHotels action to this component
    async fetchHotels() {
      try {
        const response = await axios.get(
          "  https://api.dormi.co.tz/api/getallHotel"
        );
        this.setHotels(response.data); // Call the Vuex action to update the state
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    },
    changeComponent(componentName) {
      this.currentComponent = componentName;
    },
  },
  mounted() {
    this.fetchHotels();
  },
  components: {
    maincomponent,
    Sidebar,
  },
  counter() {
    return store.state.currentItem; // Directly access the state
  },
};
</script>
<style >
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.body {
  background-color: snow;
}

header {
  height: 70px;
  width: 100vw;
  padding: 0 30px;
  background-color: #fafaff;
  position: fixed;
  z-index: 100;
  box-shadow: 1px 1px 15px rgba(161, 182, 253, 0.825);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 27px;
  font-weight: 600;
  color: rgb(47, 141, 70);
}

.icn {
  height: 30px;
}
.menuicn {
  cursor: pointer;
}

.searchbar,
.message,
.logosec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchbar2 {
  display: none;
}

.logosec {
  gap: 60px;
}

.searchbar input {
  width: 250px;
  height: 42px;
  border-radius: 50px 0 0 50px;
  background-color: #ededed;
  padding: 0 20px;
  font-size: 15px;
  outline: none;
  border: none;
}
.searchbtn {
  width: 50px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 50px 50px 0px;
  background-color: #0c007d;
  cursor: pointer;
}

.message {
  gap: 40px;
  position: relative;
  cursor: pointer;
}
.circle {
  height: 7px;
  width: 7px;
  position: absolute;
  background-color: #fa7bb4;
  border-radius: 50%;
  left: 19px;
  top: 8px;
}
.dp {
  height: 40px;
  width: 40px;
  background-color: #626262;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.main-container {
  display: flex;
  width: 100vw;
  position: relative;
  top: 70px;
  z-index: 1;
}
.dpicn {
  height: 42px;
}

.main {
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 40px 30px 30px 30px;
}

.main::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, rgb(0, 0, 85), rgb(0, 0, 50));
}
.main::-webkit-scrollbar {
  width: 5px;
}
.main::-webkit-scrollbar-track {
  background-color: #9e9e9eb2;
}

.box-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
.nav {
  min-height: 91vh;
  width: 250px;
  position: absolute;
  top: 0px;
  left: 00;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 30px 0 20px 10px;
}
.navcontainer {
  height: calc(100vh - 70px);
  width: 250px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
}
.navcontainer::-webkit-scrollbar {
  display: none;
}
.navclose {
  width: 80px;
}
.nav-option {
  width: 250px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 30px 0 20px;
  gap: 20px;
  transition: all 0.1s ease-in-out;
}
.nav-option:hover {
  border-left: 5px solid #a2a2a2;
  cursor: pointer;
}
.nav-img {
  height: 30px;
}

.nav-upper-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.option1 {
  border-left: 5px solid #010058af;
  background-color: #3f0097;
  color: white;
  cursor: pointer;
}
.option1:hover {
  border-left: 5px solid #010058af;
  background-color: #3f0097;
}
.box {
  height: 130px;
  width: 230px;
  border-radius: 20px;
  box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.box:hover {
  transform: scale(1.08);
}

.box:nth-child(1) {
  background-color: #3f0097;
}
.box:nth-child(2) {
  background-color: #5500cb;
}
.box:nth-child(3) {
  background-color: #3f0097;
}

.box:nth-child(4) {
  background-color: #5500cb;
}

.box img {
  height: 50px;
}
.box .text {
  color: white;
}
.topic {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
}

.topic-heading {
  font-size: 30px;
  letter-spacing: 3px;
}

.report-container {
  min-height: 300px;
  max-width: 1200px;
  margin: 70px auto 0px auto;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 3px 3px 10px rgb(188, 188, 188);
  padding: 0px 20px 20px 20px;
}
.report-header {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  border-bottom: 2px solid rgba(0, 20, 151, 0.59);
}

.recent-Articles {
  font-size: 30px;
  font-weight: 600;
  color: #5500cb;
}

.view {
  height: 35px;
  width: 90px;
  border-radius: 8px;
  background-color: #5500cb;
  color: white;
  font-size: 15px;
  border: none;
  cursor: pointer;
}

.report-body {
  max-width: 1160px;
  overflow-x: auto;
  padding: 20px;
}
.report-topic-heading,
.item1 {
  width: 1120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-op {
  font-size: 18px;
  letter-spacing: 0px;
}

.items {
  width: 1120px;
  margin-top: 15px;
}

.item1 {
  margin-top: 20px;
}
.t-op-nextlvl {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
}

.label-tag {
  width: 100px;
  text-align: center;
  background-color: rgb(0, 177, 0);
  color: white;
  border-radius: 4px;
}

/* Responsive CSS Here */
@media screen and (max-width: 950px) {
  .nav-img {
    height: 25px;
  }
  .nav-option {
    gap: 30px;
  }
  .nav-option h3 {
    font-size: 15px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 800px;
  }
}

@media screen and (max-width: 850px) {
  .nav-img {
    height: 30px;
  }
  .nav-option {
    gap: 30px;
  }
  .nav-option h3 {
    font-size: 20px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 700px;
  }
  .navcontainer {
    width: 100vw;
    position: absolute;
    transition: all 0.6s ease-in-out;
    top: 0;
    left: -100vw;
  }
  .nav {
    width: 100%;
    position: absolute;
  }
  .navclose {
    left: 00px;
  }
  .searchbar {
    display: none;
  }
  .main {
    padding: 40px 30px 30px 30px;
  }
  .searchbar2 {
    width: 100%;
    display: flex;
    margin: 0 0 40px 0;
    justify-content: center;
  }
  .searchbar2 input {
    width: 250px;
    height: 42px;
    border-radius: 50px 0 0 50px;
    background-color: #ededed;
    padding: 0 20px;
    font-size: 15px;
    border: 2px solid #0c007d;
  }
}

@media screen and (max-width: 490px) {
  .message {
    display: none;
  }
  .logosec {
    width: 100%;
    justify-content: space-between;
  }
  .logo {
    font-size: 20px;
  }
  .menuicn {
    height: 25px;
  }
  .nav-img {
    height: 25px;
  }
  .nav-option {
    gap: 25px;
  }
  .nav-option h3 {
    font-size: 12px;
  }
  .nav-upper-options {
    gap: 15px;
  }
  .recent-Articles {
    font-size: 20px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 550px;
  }
}

@media screen and (max-width: 400px) {
  .recent-Articles {
    font-size: 17px;
  }
  .view {
    width: 60px;
    font-size: 10px;
    height: 27px;
  }
  .report-header {
    height: 60px;
    padding: 10px 10px 5px 10px;
  }
  .searchbtn img {
    height: 20px;
  }
}

@media screen and (max-width: 320px) {
  .recent-Articles {
    font-size: 12px;
  }
  .view {
    width: 50px;
    font-size: 8px;
    height: 27px;
  }
  .report-header {
    height: 60px;
    padding: 10px 5px 5px 5px;
  }
  .t-op {
    font-size: 12px;
  }
  .t-op-nextlvl {
    font-size: 10px;
  }
  .report-topic-heading,
  .item1,
  .items {
    width: 300px;
  }
  .report-body {
    padding: 10px;
  }
  .label-tag {
    width: 70px;
  }
  .searchbtn {
    width: 40px;
  }
  .searchbar2 input {
    width: 180px;
  }
}

.data {
  z-index: 990000;
}
</style>