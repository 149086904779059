<template>
  <div
    id="docs-sidebar"
    class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 overflow-y-auto scrollbar-y lg:block md:block lg:translate-x-0 lg:right-auto lg:bottom-0 dark:scrollbar-y shadow-lg"
    style="margin-top: 70px"
  >
    <nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap">
      <ul class="space-y-1.5">
        <li class="mb-2">
          <router-link to="/pageformanager">
            <a
              class="flex items-center gap-2 px-4 py-2 bg-gray-100 text-sm text-slate-700 rounded-md hover:bg-gray-200 dark:bg-gray-900 dark:text-white"
              href="javascript:;"
            >
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <!-- ... Dashboard icon ... -->
              </svg>
              Dashboard
            </a>
          </router-link>
        </li>

     

        <li class="mb-2">
          <router-link to="/editingmanager">
            <a
              class="flex items-center gap-x-3.5 py-2 px-4 text-sm text-slate-700 rounded-md hover:bg-gray-100 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300"
              href="javascript:;"
            >
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <!-- ... Manage icon ... -->
              </svg>
              Manage
            </a>
          </router-link>
        </li>

        <router-link to="/password/reset" exact-active-class="active-link">
          <button
            class="flex items-center gap-x-3.5 py-2 px-4 text-sm rounded-md"
            :class="{ 'text-slate-700': $route.path !== '/password/reset' }"
          >
            <i class="fas fa-cog"></i> Setting
          </button>
        </router-link>

        <button
          @click="logout"
          class="flex items-center gap-x-3.5 py-2 px-4 text-sm text-white rounded-md"
        >
          <i class="fas fa-sign-out-alt"></i> Logout
        </button>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  ...mapActions(["logout"]),
  methods: {
    changeContent(item) {
      this.$store.dispatch("selectItem", item);
    },
    logout() {
      this.$store.dispatch("logout");
      // Redirect to /
      this.$router.push("/");
    },
  },
};
</script>
