<template>
  <nav class="nav shadow-lg">
    <div class="nav-upper-options">
      <div class="nav-option option1">
        <img
          src="https://media.geeksforgeeks.org/wp-content/uploads/20221210182148/Untitled-design-(29).png"
          class="nav-img"
          alt="dashboard"
        />
        <router-link to="/homepage">Dashboard</router-link>
      </div>

      <div class="option2 nav-option">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 448 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
          />
        </svg>
        <router-link to="/addinghotel">AddingHotel</router-link>
      </div>

      <div class="nav-option option3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 448 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"
          />
        </svg>
        <router-link to="/bookedhotel">All Booking</router-link>
      </div>

      <div class="nav-option option4 shadow-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <!-- Your SVG icon path here -->
          <path
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-3.31 0-6 2.69-6 6 0 1.97 1 3.72 2.57 4.76.19.13.45.13.63 0 .42-.29.52-.85.23-1.26C8.63 16.1 10.29 16 12 16s3.37.1 4.57.5c.29.4.19.97-.23 1.26-.19.13-.44.13-.63 0C13 17.72 12.15 18 12 18c-2.76 0-5-2.24-5-5s2.24-5 5-5c1.03 0 2.05.31 2.87.87.31.21.39.64.18.95-.21.31-.64.39-.95.18C14.04 6.13 13.03 6 12 6z"
          />
        </svg>
        <router-link to="/managerDetail">Manager</router-link>
      </div>

      <div class="nav-option logout">
        <router-link to="/manageHotelPayment">
          <i class="fas fa-credit-card"></i> Payments
        </router-link>
      </div>
      <div class="nav-option logout">
        <router-link to="/contactusUsers">
          <i class="fas fa-envelope"></i> contactus
        </router-link>
      </div>

      <div class="nav-option logout" @click="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
          />
        </svg>
        <h3>Logout</h3>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  methods: {
    changeContent(item) {
      this.$store.dispatch("selectItem", item);
    },
    logout() {
      this.$store.dispatch("logout");
      //redirect to /
      this.$router.push("/");
    },
    
  },

};
</script>




