<template>
{{ this.$store.state.bookeddata }}
  <div class="container mx-auto px-4 mt-10 py-8">
    <div v-if="loading" class="flex justify-center items-center h-96">
      <div class="loading-spinner"></div>
    </div>
    <div v-else class="flex flex-wrap -mx-4">
      <div
        v-for="item in data.hotels"
        :key="item.id"
        class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-8"
      >
        <div class="bg-white rounded-lg h-96">
          <div>
            <Swiper
              :effect="'cube'"
              :grabCursor="true"
              :cubeEffect="{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }"
              :pagination="true"
              :modules="modules"
              class="mySwiper"
            >
              <SwiperSlide v-for="it in item.photoes" :key="it.id">
                <router-link :to="{ name: 'hotelProfile', params: { id: item.id } }">
      <img
        :src="it.photo_url"
        alt="Card Image"
        class="w-full h-56 object-cover rounded-md mb-4 swiper-img"
        @load="imageLoaded"
      />
    </router-link>
                <i
                  class="fa fa-heart like-icon"
                  @click="like(item.id, it.photo_url)"
                ></i>
              </SwiperSlide>
            </Swiper>
          </div>
          <div class="p-4 text-left">
            <button @click="navigateToHotelProfile(item)">
              <h2 class="text-xl font-bold underline">
                <h2 class="text-xl text-sm small">{{ item.name }}</h2>
              </h2>
            </button>
            <h2 class="text-sm">{{ item.hcity }},{{ item.hadress }}</h2>
            <h2 class="text-xl text-sm font-bold">
              TZS {{ item.price.toLocaleString() }}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overlay" v-if="showlike">
    <div class="attop w-100">
      <div
        class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      >
        <div class="md:flex">
          <div class="p-8">
            <div
              class="uppercase tracking-wide text-sm text-indigo-500 font-semibold"
            >
              <input
                type="text"
                class="border border-black rounded-md p-2 w-full"
                style="width: 200px"
                v-model="phonenumbe"
                @input="savedatabase"
                placeholder="phone"
              />
              <p v-if="validationMessage">{{ validationMessage }}</p>
              <p v-else>Enter correct phone number</p>
            </div>
            <div class="flex flex-row gap-1 justify-evenly">
              <button
              class="flex mt-10 items-center gap-2 px-3 py-2 bg-blue-500 text-white rounded-md"
              @click="savedatabase"
            >
              <i
                class="fas fa-heart"
                :class="[
                  likenow ? 'red-heart' : '',
                  likenow ? 'large-heart' : '',
                ]"
                @click="likeNowtome"
              ></i>
             
             
            </button>

            <button class="flex mt-10 items-center gap-2 px-3 py-2 bg-blue-500 text-white rounded-md" @click="close">
                  <i class="fas fa-times-circle"></i>
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/api.js";

import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCube, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      data: {},
      loading: true,
      loadedImages: 0,
      image: "",
      id: "",
      likedetail:{},
      phonenumbe: "",
      validationMessage: "",
      likenow: false,
      showlike: false,
    };
  },
  props: {
    hotels: [],
  },
  async created() {
    try {
      const response = await api.get("hotels");
      this.data = response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    navigateToHotelProfile(item) {
      this.$router.push({ name: "hotelProfile", params: { id: item.id } });
    },
    like(hotelId, photo) {
      this.image = photo;
      this.id = hotelId;
      this.showlike = true;
    },
    savedatabase() {
      const phoneNumberRegex = /^\d{10}$/;

      if (phoneNumberRegex.test(this.phonenumbe)) {
        this.validationMessage = "Valid phone number!";
        (this.likenow = true), console.log(this.phonenumbe);
      } else {
        this.validationMessage = "Invalid phone number.";
        this.likenow = false;
      }
    },
    likeNowtome()
    {
       if(this.likenow)
       {
        console.log('this is the best');
        const likedetail = {
          hoteid : this.id,
          phonenumber: this.phonenumbe
        }
        fetch("  https://api.dormi.co.tz/api/likehotel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(likedetail),
      })
        .then((response) => {
          console.log(likedetail);
          if (!response.ok) {
            alert('You have already like ');
          }
          return response.json();
        })
        .then((responseData) => {
          console.log(responseData);
          alert('thanks for like');
        })
        .catch((error) => {
          // Handle errors
          console.error("Error inserting data:", error);
          console.log("there is the problem");
        });

        this.phonenumbe = "";
       }
       else 
       {
        alert('insert the right number');
       }
    },
    imageLoaded() {
      this.loadedImages++;
      if (this.loadedImages === this.getTotalImageCount()) {
        this.loading = false;
      }
      
    },
    close()
    {
      this.showlike = false;
    },
    getTotalImageCount() {
      return this.data.hotels.reduce(
        (count, item) => count + item.photoes.length,
        0
      );
    },
  },
  setup() {
    return {
      modules: [EffectCube, Pagination],
    };
  },
  computed: {
    showError() {
      // Replace this regular expression with your validation logic
      const phoneRegex = /^\d{10}$/; // Example: 10-digit phone number

      return !phoneRegex.test(this.phoneNumber);
    },
  },
};
</script>

<style>
.small {
  font-size: 15px;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Replace with your own styles */
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
}

.swiper-img {
  box-shadow: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-cube-shadow {
  display: none;
}

.swiper-pagination {
  padding-bottom: 10px;
}

.like-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(216, 202, 202);
  font-size: 24px;
}

.underline {
  text-decoration: underline;
}
.attop {
  position: absolute;

  left: 50%;
  top: 400px;
}
.overlay {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77);
  top: 0;
  position: fixed;
}
.red-heart {
  color: red; /* Apply the red color to the heart icon */
  cursor: pointer;
}
</style>
