<template>
  <div v-if="loading" class="overlay">
    <!-- Add your preloader HTML/CSS here -->
    <div class="spinner"></div>
  </div>
  <section class="max-2 bg-white shadow-lg mx-5">
    <p class="text-center undeline font-semibold text-gray-800">
      Manage Pictures
    </p>
    <swiper
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }"
      :pagination="true"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="photo in hotelinformation.hotelphoto" :key="photo.id"
        ><img :src="photo.photo_url" @click="deletePhoto(photo.id)" />
      </swiper-slide>
    </swiper>
    <button
      @click.prevent="UploadServicephoto"
      class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 mb-2 rounded-sm shadow-md hover:shadow-lg w-full flex items-center justify-center"
    >
      <span class="mr-2">Add Photo</span>
      <i class="fa fa-camera"></i>
    </button>
  </section>

  <section class="max-2 bg-white shadow-lg mx-5 mt-2">
    <p class="text-center undeline font-semibold text-gray-800 mt-5">
      Manage Services
    </p>
    <div class="mt-3 shadow-sm mx-5">
      <transition-group name="fade" tag="li">
        <li
          v-for="service in presenteservice.hotelservices"
          :key="service.id"
          class="service-item flex items-center mb-3"
        >
          <span class="service-icon text-blue-600"
            ><i class="fa" :class="service.icon"></i
          ></span>
          <span class="ml-2">{{ service.sname }}</span>
          <button
            @click="deleteService(service.id)"
            class="ml-auto p-2 rounded-sm mt-1 bg-red-500 hover:bg-red-600 text-white transition-transform transform hover:scale-110"
          >
            Delete
          </button>
        </li>
        <hr />
      </transition-group>
    </div>
    <button
      @click="addServicehere"
      class="w-full shadow-lg bg-blue-500 hover:bg-blue-600 text-white px-4 py-2"
    >
      <i class="fa fa-plus mr-2"></i> Add Service
    </button>
  </section>

  <section class="w-full shadow-lg mt-4">
    <div class="container mx-4">
      <div
        class="px-4 py-6 bg-white border shadow-lg border-sm justify-items-center rounded-lg"
      >
        <div>
          <a
            href="#"
            class="inline-block mb-4 text-xl font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600 transition-colors duration-300 ease-in-out"
            >Short Information</a
          >
          <div v-if="presenteservice.description">
            <textarea
              class="resize-none border rounded-lg focus:outline-none focus:ring shadow-lg w-full py-2 px-3 text-gray-700 leading-tight"
              cols="30"
              rows="10"
              v-model="information"
              placeholder="Enter your information here"
            ></textarea>
          </div>
        </div>
        <div>
          <button
            type="button"
            class="inline-block mt-2 px-4 w-full py-2 text-base font-bold text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:from-blue-600 hover:via-blue-700 hover:to-blue-800 rounded-lg shadow-md transform transition-transform hover:scale-105 duration-300 ease-in-out"
            @click="upgradeservice"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </section>

  <div v-if="addingservice">
    <div class="fixed inset-0 flex items-center justify-center z-50">
      <div class="bg-gray-900 bg-opacity-50 absolute inset-0"></div>
      <div
        class="relative z-10 bg-white rounded-lg overflow-hidden shadow-md w-full max-w-sm mx-auto"
      >
        <form class="px-8 py-6">
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="serviceSelect"
              >Select a Service</label
            >
            <select
              class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
              id="serviceSelect"
              v-model="icon"
            >
              <option value="" disabled>Select a service</option>
              <option v-for="item in service" :key="item.id" :value="item.icon">
                {{ item.sname }}
              </option>
            </select>
          </div>
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="serviceName"
              >Enter Service Name</label
            >
            <input
              class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
              id="serviceName"
              type="text"
              placeholder="Enter service Name"
              v-model="servicename"
            />
          </div>
          <button
            @click.prevent="UploadService"
            class="bg-blue-500 hover:bg-blue-600 w-full text-white font-semibold py-2 px-4 rounded-full shadow-md"
          >
            <i class="fa fa-plus mr-2"></i> Add Service
          </button>
        </form>
      </div>
    </div>
  </div>

  <div v-if="image">
    <div class="overlay">
      <div class="w-full max-w-xs">
        <form
          class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          @submit.prevent="uploadPhoto"
        >
          <!-- Close Button -->
          <button
            @click="closeForm"
            class="absolute top-0 right-0 m-3 text-gray-600 hover:text-red-600"
          >
            <i class="fa fa-times"></i>
          </button>

          <div
            v-if="sucess"
            class="fixed inset-x-0 bottom-0 flex justify-center items-center py-2 bg-green-500 text-white animate-slide-up"
          >
            <p class="text-center">Image uploaded successfully!</p>
          </div>
          <!-- Image Preview -->
          <div class="mb-4">
            <img
              v-if="imageUrl"
              :src="imageUrl"
              alt="Image Preview"
              class="w-full h-auto"
            />
          </div>
          <div class="mb-4">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="serviceSelect"
              >Select a Service</label
            >
            <input
              type="file"
              ref="photoInput"
              accept="image/*"
              @change="previewImage"
            />
          </div>
          <div class="mb-6">
            <label
              class="block text-gray-700 text-sm font-bold mb-2"
              for="password"
              >Enter Caption</label
            >
            <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="text"
              hidden
              placeholder="Caption"
              v-model="caption"
            />
          </div>
          <div class="flex justify-center items-center space-x-2">
            <button
              type="submit"
              class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transform transition-transform hover:scale-105"
            >
              <i class="fa fa-plus mr-2"></i> Add Service
            </button>
            <button
              @click="close"
              class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-full shadow-md transform transition-transform hover:scale-105"
            >
              <i class="fas fa-times mr-2"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import store from "../../store/index";
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { mapActions } from "vuex";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import axios from "axios";
export default {
  data() {
    return {
      hotelinformation: {},
      manager: {},
      hotelid: "",
      photo: "",
      sucess: false,
      servicename: "",
      image: false,
      imageUrl: null,
      loading: false,
      icon: "",
      caption: "welcome",
      service: {},
      presenteservice: {},
      addingservice: false,
      descriptio: null,
      information: "",
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
  mounted() {
    this.manager = store.state.manager;
    this.hotelid = this.manager.hotelid;
    this.showallservice();
    this.fearchhoteldata();
    this.showallhoteservice();
  },
  methods: {
    ...mapActions(["ServiceOfhotel"]),
    async fearchhoteldata() {
      this.loading = true; // Set loading to true before making the request

      try {
        const formdata = {
          hotelid: this.hotelid,
        };
        const response = await axios.post(
          "https://api.dormi.co.tz/api/hotelinfomationformanager",
          formdata
        );
        this.hotelinformation = response.data;
      } catch (error) {
        console.error("Error fetching hotel data:", error);
        // Handle errors here
      } finally {
        this.loading = false; // Set loading back to false after the request
      }
    },

    async deletePhoto(id) {
      if (confirm("your wnat to delete this")) {
        const forminfo = {
          photoid: id,
        };
        const response = await axios.post(
          "https://api.dormi.co.tz/api/deletephoto",
          forminfo
        );
        if (response) {
          this.fearchhoteldata();
        }
      }
    },

    async showallservice() {
      const response = await fetch("https://api.dormi.co.tz/api/service");
      const data = await response.json();
      this.service = data;
    },

    async deleteService(id) {
      const forminfo = {
        service: id,
      };

      const response = await axios.post(
        "https://api.dormi.co.tz/api/deleteservicehere",
        forminfo
      );

      this.showallhoteservice();

      console.log(response);
    },

    addServicehere() {
      this.addingservice = true;
    },

    async showallhoteservice() {
      const response = await fetch(
        `https://api.dormi.co.tz/api/hotelservice/${this.hotelid}`
      );
      const data = await response.json();
      this.presenteservice = data;

      this.descriptio = this.presenteservice.description[0].description;
      this.information = this.descriptio;
    },

    async UploadService() {
      const formdata = {
        hotelid: this.hotelid,
        icon: this.icon,
        sname: this.servicename,
      };
      const response = await axios.post(
        "https://api.dormi.co.tz/api/storeservice",
        formdata
      );
      console.log(response);
      this.addingservice = false;
      this.showallhoteservice();
    },

    UploadServicephoto() {
      this.image = true;
    },

    previewImage() {
      const fileInput = this.$refs.photoInput;
      const file = fileInput.files[0];
      if (file) {
        // Create a URL for the selected image
        this.imageUrl = URL.createObjectURL(file);
      } else {
        this.imageUrl = null;
      }
    },

    close() {
      this.image = false;
    },

    async uploadPhoto() {
      var formData = new FormData();
      formData.append("photo_url", this.$refs.photoInput.files[0]);
      formData.append("hotel_id", this.hotelid);
      formData.append("caption", this.caption);

      try {
        const response = await axios.post(
          "https://api.dormi.co.tz/api/uploadphoto",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Photo uploaded:", response.data);
        this.addingpicture = false;
        this.sucess = true;
        this.imageUrl = null;
        alert("photo uploaded sucessifully");
        this.fearchhoteldata();
        formData = [];

        // You can handle success here, e.g., show a success message
      } catch (error) {
        console.error("Error uploading photo:", error);
        // Handle errors here, e.g., show an error message
      }
    },
    async upgradeservice() {
      const formdata = {
        hotelid: this.hotelid,
        bestofall: this.information,
      };

      const response = await axios.post(
        "https://api.dormi.co.tz/api/hoteldescription",
        formdata
      );

      if (response) {
        alert("Data inserted sucessifully");
      }
    },
  },
};
</script>


<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.77);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>



  



