<template>
  <div class="marginbotom">
    <Navbar class="mb-20"/>
  </div>

  <div class="container mx-auto px-4 py-8 ">
    <div
      v-if="searchResults.hotels.length === 0"
      class="text-center grid grid-cols-1 place-items-center no-results container w-full  bg-black-600 rounded-sm shadow-lg"
      :class="{ 'hidden': searchResults.hotels.length > 0 }"
    >
      <img
        src="https://cdn.dribbble.com/users/1785628/screenshots/5605512/media/097297f8e21d501ba45d7ce437ed77bd.gif"
      />
    </div>
    <div v-else class="flex flex-wrap -mx-4">
      <div
        v-for="item in searchResults.hotels"
        :key="item.id"
        class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-4 mb-8"
      >
        <div class="bg-white rounded-lg h-96">
          <div>
            <Swiper
              :effect="'cube'"
              :grabCursor="true"
              :cubeEffect="{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }"
              :pagination="true"
              :modules="modules"
              class="mySwiper"
            >
              <SwiperSlide v-for="it in item.photoes" :key="it.id">
                <img
                  :src="it.photo_url"
                  alt="Card Image"
                  class="w-full h-56 object-cover rounded-md mb-4 swiper-img"
                  @load="imageLoaded"
                />
                <i class="fa fa-heart like-icon" @click="like(item.id)"></i>
              </SwiperSlide>
            </Swiper>
          </div>
          <div class="p-4 text-left">
            <button @click="navigateToHotelProfile(item)">
              <h2 class="text-xl font-bold underline">
                <h2 class="text-xl text-sm small">{{ item.name }}</h2>
              </h2>
            </button>
            <h2 class="text-sm">{{ item.hcity }},{{ item.hadress}}</h2>
            <h2 class="text-xl text-sm font-bold">TZS {{ item.price.toLocaleString()}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Navbar from "../components/Navbar-component.vue";
import Footer from "../components/Footer-Component.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCube, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Navbar,
    Footer
  },
  computed: {
    searchResults() {
      return this.$store.getters.getSearchResults;
    },
  },
  methods: {
    navigateToHotelProfile(item) {
      this.$router.push({ name: "hotelProfile", params: { id: item.id } });
    },
  },
  setup() {
    return {
      modules: [EffectCube, Pagination],
    };
  },
  // Rest of the component code
};
</script>

<style>
.small {
  font-size: 15px;
}

.loading-spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  /* Replace with your own styles */
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
}

.swiper-img {
  box-shadow: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-cube-shadow {
  display: none;
}

.swiper-pagination {
  padding-bottom: 10px;
}

.like-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(216, 202, 202);
  font-size: 24px;
}

.underline {
  text-decoration: underline;
}
small {
  font-size: 15px;
}

.loading-spinner {
  /* Existing styles for the spinner */
}

/* Add new styles for the animated message */
.no-results {
  @apply transition-opacity duration-500 ease-in-out;
  opacity: 1;
}

.no-results.hidden {
  opacity: 0;
}
.marginbotom
{
  margin-bottom:50px;
}
</style>
