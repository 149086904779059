<template>
  {{ $store.state.ouserservice }}
  <div class="mx-aux container bg-white w-full">
    <div class="flex-col sm:flex-row block sm:hidden">
      <swiper
        :effect="'flip'"
        :grabCursor="true"
        :pagination="true"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="photo in hotels.photos" :key="photo.id">
          <template v-if="photo && photo.photo_url">
            <img :src="photo.photo_url" />
          </template>
        </swiper-slide>
      </swiper>
    </div>

    <div class="flex-col sm:flex-row hidden sm:flex">
      <div class="sm:w-1/2 w-full  h-100 sm:px-10 sm:h-50 sm:w-50" v-if="hotelData">
        <img
          :src="hotelData.HmainIMagePath"
          alt=""
          class="rounded-md shrink-0 w-full h-full "
         
        />
      </div>

      <div class="sm:w-1/2 w-full sm:px-0">
        <div v-if="hotels && hotels.photos">
          <div class="flex flex-col">
            <div class="w-full sm:flex gap-1 mb-1">
              <div
                v-for="photo in hotels.photos.slice(0, 2)"
                :key="photo.id"
                class="sm:w-1/2 w-full"
              >
                <img
                  :src="photo.photo_url"
                  :alt="photo.caption"
                  class="shrink-0 w-full h-full object-cover"
                />
              </div>
            </div>
            <div class="w-full sm:flex gap-1">
              <div
                v-for="photo in hotels.photos.slice(2)"
                :key="photo.id"
                class="sm:w-1/2 w-full"
              >
                <img
                  :src="photo.photo_url"
                  :alt="photo.caption"
                  class="shrink-0 w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFlip, Pagination, Navigation } from "swiper/modules";

export default {
  props: {
    hotelData: {
      type: Object,
      required: true,
    },
    hotels: {
      type: Object,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      data: [],
      loading: true,
    };
  },

  setup() {
    return {
      modules: [EffectFlip, Pagination, Navigation],
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  padding: 0;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-button-next,
.swiper-button-prev {
  display: none;
}
</style>
