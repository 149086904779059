<template>
    <Navbar/>
    <Hotel/>
    <FootComponent></FootComponent>
    <router-view/>
</template>

<script>
import Navbar from '../components/Navbar-component.vue';
import Hotel from '../components/Hotel-Component.vue';
import FootComponent from '../components/Footer-Component.vue';

export default{

    components:
    {
       Navbar, 
       Hotel,
       FootComponent
       
    }
}

</script>






<style>

</style>