<template>
   <Navbar></Navbar>
    <Help></Help>
   <Footer></Footer>
</template>

<script>
import Navbar from '../components/Navbar-component.vue';
import Footer from '../components/Footer-Component.vue';
import Help from '../components/help-component.vue';
export default 
{
    components: {
        Navbar,
        Footer,
        Help
    },

}
</script>