// api.js
import axios from 'axios';

const baseURL = 'https://api.dormi.co.tz/api/';
// Create separate Axios instances for each API
const hotels = axios.create({
  baseURL, // Replace with the base URL of API 1
  // Other configurations specific to API 1
});



export default hotels;

