<template>
<div class="shadow-md rounded-lg overflow-hidden  border-spacing-0 mx-10">
  <div class="flex shadow-sm text-start">
  <div class="p-4 space-y-2" v-for="user in userinf.guestinfo" :key="user.id">
    <p class="text-xl font-semibold">User Information</p>
    <div class="bg-white rounded-lg shadow-md p-4">
      <h2 class="text-gray-800 text-lg mb-2"><i class="fas fa-user mr-2"></i> {{ user.gFirstName }} {{ user.gLastName }}</h2>
      <p class="text-gray-600"><i class="fas fa-envelope mr-2"></i> Email: {{ user.gEmail }}</p>
      <p class="text-gray-600"><i class="fas fa-map-marker-alt mr-2"></i> Location: {{ user.gCity }}, {{ user.gState }}, {{ user.gCountry }}</p>
    </div>
  </div>
</div>




<!-- Booking Information -->
<div class="p-4 space-y-2 text-start" v-for="booking in userinf.booking" :key="booking.id">
  <h2 class="text-xl font-semibold text-gray-800 text-start animate__animated animate__fadeIn text-shadow-md">
  Booking Information
</h2>
  <div class="bg-white rounded-lg shadow-md p-4">
    <p class="text-gray-600"><i class="fas fa-calendar mr-2"></i> Check-in Date: {{ booking.bDateFrom }}</p>
    <p class="text-gray-600"><i class="fas fa-calendar mr-2"></i> Check-out Date: {{ booking.bDateTo }}</p>
    <p class="text-gray-600"><i class="fas fa-user mr-2"></i> Adults: {{ booking.Adult }}</p>
    <p class="text-gray-600"><i class="fas fa-baby mr-2"></i> Infants: {{ booking.Ifant }}</p>
    <p class="text-gray-600"><i class="fas fa-child mr-2"></i> Children: {{ booking.child }}</p>
  </div>
</div>

</div>
</template>
<script>
import axios from 'axios';
import store from "../../store/index";
export default {

   
    data()
    {
        return {
            user:'',
            userinf:{},
            hotelid:{},
            manager:{}
        }
    },
    props: ['userid'],

    created(){
        this.user = this.$route.params.userid;
        const manageedata = store.state.manager;
        this.manager= manageedata;
        this.hotelid = this.manager.hotelid;
        this.featchuserdetail();
    },

    methods:{
        async featchuserdetail()
        {   const userinfo ={
            userid: this.user,
            hotelid:this.hotelid,
        }
           const response = await axios.post('https://api.dormi.co.tz/api/guestinfo',userinfo);
           this.userinf = response.data;
        }
    },





}
</script>