<template>
  <div class="max-w-md mx-auto p-6 bg-white rounded shadow-md">
<form @submit.prevent="submitForm" enctype="multipart/form-data">
<div class="mb-4">
  <label for="name" class="block text-sm font-medium text-gray-600">Hotel Name:</label>
  <input v-model="formData.name" type="text" id="name" name="name" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="totalroom" class="block text-sm font-medium text-gray-600">Total Rooms:</label>
  <input v-model="formData.totalroom" type="number" id="totalroom" name="totalroom" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="price" class="block text-sm font-medium text-gray-600">Price:</label>
  <input v-model="formData.price" type="number" id="price" name="price" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hmoto" class="block text-sm font-medium text-gray-600">Motto:</label>
  <input v-model="formData.hmoto" type="text" id="hmoto" name="hmoto" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hadress" class="block text-sm font-medium text-gray-600">Address:</label>
  <input v-model="formData.hadress" type="text" id="hadress" name="hadress" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hAdress2" class="block text-sm font-medium text-gray-600">Address 2:</label>
  <input v-model="formData.hAdress2" type="text" id="hAdress2" name="hAdress2" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hmainPhoneNumber" class="block text-sm font-medium text-gray-600">Phone Number:</label>
  <input v-model="formData.hmainPhoneNumber" type="text" id="hmainPhoneNumber" name="hmainPhoneNumber" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="latitude" class="block text-sm font-medium text-gray-600">Latitude:</label>
  <input v-model="formData.latitude" type="text" id="latitude" name="latitude" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="longitude" class="block text-sm font-medium text-gray-600">Longitude:</label>
  <input v-model="formData.longitude" type="text" id="longitude" name="longitude" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="HmainImagePath" class="block text-sm font-medium text-gray-600">Main Image:</label>
  <input type="file" id="HmainImagePath" name="HmainImagePath" @change="handleImageUpload" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hcity" class="block text-sm font-medium text-gray-600">City:</label>
  <input v-model="formData.hcity" type="text" id="hcity" name="hcity" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="hstate" class="block text-sm font-medium text-gray-600">State:</label>
  <input v-model="formData.hstate" type="text" id="hstate" name="hstate" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <label for="htype" class="block text-sm font-medium text-gray-600">Type:</label>
  <input v-model="formData.htype" type="text" id="htype" name="htype" required class="mt-1 p-2 w-full border rounded-md">
</div>

<div class="mb-4">
  <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Register Hotel</button>
</div>
</form>

</div>
</template>
 


<script>
import axios from 'axios';
export default{

 data() {
    return {
      showSuccessMessage: false,
      showSuccessMessageerror:false,
      formData: {
        name: '',
        totalroom: '',
        price: '',
        hmoto: '',
        hadress: '',
        hAdress2: '',
        hmainPhoneNumber: '',
        latitude: '',
        longitude: '',
        HmainImagePath: null,
        hcity: '',
        hstate: '',
        htype: '',
      },
    };
  },
  methods: {
    handleImageUpload(event) {
      this.formData.HmainImagePath = event.target.files[0];
    },
    submitForm() {
      const formData = new FormData();
      for (const key in this.formData) {
        formData.append(key, this.formData[key]);
      }

      

      axios.post('https://api.dormi.co.tz/api/addingHotel', formData)
        .then(response => {
          console.log('Hotel registered successfully:', response.data);
          this.showSuccessMessage = true; // Show the success message
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide the success message after 2 seconds
          }, 3000);
          this.formData.HmainImagePath = "";
          this.formData.hAdress2='';
          this.formData.htype="";
          this.formData.hadress="";
          this.formData.hmainPhoneNumber="";
          this.formData.latitude="";
          this.formData.longitude="";
          this.formData.name="";
          this.formData.price="";
          this.formData.totalroom=""
        })
        .catch(error => {
         this.showSuccessMessageerror = true;
         setTimeout(() => {
            this.showSuccessMessageerror = false; // Hide the success message after 2 seconds
          }, 3000);
          console.error('Error registering hotel:', error);
        });
    },
  },
   
  
};


</script>
