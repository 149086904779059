<!-- src/components/TermsOfService.vue -->
<template>
  <div>
    <Nav></Nav>
  </div>

  <div class="content">
    <div class=" bg-whit min-h-screen flex items-center justify-center">
    <div class="w-full max-w-lg rounded-lg bg-white shadow-md p-8">
      <h1 class="text-3xl font-bold  bg-whit text-center mb-6">
        Dormi Terms of Service
      </h1>

      <div class="text-base">
        <p class="mb-4">
          These terms of service ("Terms", "Agreement") are an agreement between
          Dormi ("us", "we", or "our") and you ("User", "you" or "your"). This
          Agreement sets forth the general terms and conditions of your use of
          the Dormi platform and any of its products or services (collectively,
          "Website" or "Services").
        </p>

        <h2 class="text-lg font-semibold mb-2">Accounts and Membership</h2>
        <p class="mb-4">
          If you create an account on the Dormi platform, you are responsible
          for maintaining the security of your account and you are fully
          responsible for all activities that occur under the account and any
          other actions taken in connection with it. We may, but have no
          obligation to, monitor and review new accounts before you may sign in
          and use our Services. Providing false contact information of any kind
          may result in the termination of your account. You must immediately
          notify us of any unauthorized uses of your account or any other
          breaches of security. We will not be liable for any acts or omissions
          by you, including any damages of any kind incurred as a result of such
          acts or omissions.
        </p>

        <h2 class="text-lg font-semibold mb-2">Prohibited uses</h2>
        <p class="mb-4">
          You may not use the Dormi platform for any unlawful purpose or to
          violate any laws in your jurisdiction, including but not limited to
          copyright laws. You agree not to use the Service to upload, post,
          transmit, share, store or otherwise make available any content that we
          may deem to be harmful, threatening, unlawful, defamatory, infringing,
          abusive, inflammatory, harassing, vulgar, obscene, fraudulent,
          invasive of privacy or publicity rights, hateful, or racially,
          ethnically or otherwise objectionable. You may not interfere with
          other users' enjoyment of the Service. You may not impersonate any
          person or entity, falsely claim an affiliation with any person or
          entity, or access the Dormi platform accounts of others without
          permission.
        </p>

        <h2 class="text-lg font-semibold mb-2">Termination</h2>
        <p class="mb-4">
          We may terminate or suspend your access to the Dormi platform and
          Services immediately, without prior notice or liability, for any
          reason whatsoever, including without limitation if you breach the
          Terms. Upon termination, your right to use the Service will
          immediately cease. If you wish to terminate your account, you may
          simply discontinue using the Service.
        </p>

        <!-- Add more sections of the Terms of Service here -->
      </div>
    </div>
  </div>
  </div>

  

  <Footer />
</template>
  
  <script>
import Nav from "../components/Navbar-component.vue";
import Footer from "../components/Footer-Component.vue";
export default {
  name: "TermsOfService",
  components: {
    Nav,
    Footer,
  },
};
</script>
  
  <style scoped>
/* Custom Styles */

.content
{
  padding-top: 100px;
  padding-bottom: 50px;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.container {
  margin-bottom: 100px;
}
</style>
  