import { createRouter,createWebHistory } from "vue-router";
import Home from '../views/Home-Controller.vue';
import HotelProfile from '../views/Hotel-Profile.vue';
import SearchResults from '../views/Search-Controller.vue';
import NotFound from '../views/LinkNotfound.vue';
import Booking from '../Booking/Booking-Component.vue';
import Login from '../components/Login-Contoller.vue';
import store from '../store/index';
import Hostdetailview from "../Admin/components/HotelAdminView-component.vue";
import Addinghotel from '../Admin/components/AddingHotel-component.vue';
import AdminDashboard from '../Admin/Layouts-Component.vue/index_component.vue';
import Hotebooking     from '../Admin/components/booking-component.vue';
import Adminhomepage  from '../Admin/components/homepage-component.vue';
import securityPolicy from '../components/Security-component.vue';
import HotelEdit from '../Admin/components/Editing-component.vue';
import Terms from '../components/Terms-component.vue';
import ManagerPage from '../manager/index-compoment.vue';
import managerEditing from '../manager/components/Editing-component.vue';
import Hotel from "../Admin/components/homepage-component.vue";
import Content from "../Admin/Layouts-Component.vue/index_component.vue";
import GuestInfomation  from "../manager/components/Guest_component.vue"
import welcomeManager from "../manager/components/homepage-component.vue";
import invoice from "../Booking/invoice-component.vue";
import Help from "../views/help-component.vue";
import Contactus from "../views/contactus-component.vue";
import manageHotelPayment from "../Admin/components/HomeHotelPrices-Component.vue";
import ManagerInfo from "../Admin/components/managerdata_info.vue";
import ReportGenerate from "../manager/components/Report-component.vue";
import ContactusUsers from "../Admin/components/contactus-compnent.vue";
import password from "../manager/components/Password-component.vue";

import InvoiceCheck from "../views/Invoice-component.vue";

function isManager(to, from, next) {
    if(store.state.role == 'manager'){
        next();
    }
    else{
        next('/login');
    }
}
function isAdmin(to, from, next) {

    if(store.state.role == 'admin'){    
        next();
    }
    else{
        next('/login');
    }
}

function isUser(to, from, next) {
    if(store.state.role == 'admin' || store.state.role == 'manager'){
        next('/');
    }
    else{

        next();
    }
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component:Home
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: Contactus
    },
    {
     path:'/terms',
     name:'terms',
     component: Terms 
    },
    {
      path: '/help',
        name: 'help',
        component: Help
    },

    // Redirect to default content
    { path: '/content/:item', component: Content },

    {
        path: '/hotelAdding',
        name: 'Hotel',
        component:Hotel
    },
    {
        path: '/invoice',
        name: 'invoice',
        component:invoice,
      


        props: true, 
    
    },


    {
        path: '/manager',
        name: 'manager',
        component:ManagerPage,
        beforeEnter: isManager,
        children:[
            {
                path:'/editingmanager',
                name:'editingmanager',
                component:managerEditing,
            },
            {
                path:'/reportGenerator',
                name:'Report',
                component:ReportGenerate
            },
            {
                path:'/homepage',
                name:'homepage',
                component:Adminhomepage,
            },
            {
                path:'/pageformanager',
                name:'pageformanager',
                component:welcomeManager,
            },
            {
                path: '/guesting/:userid',
                name: 'guesting',
                component:GuestInfomation,
                props: true,
            },
            {
                path: '/password/reset',
                name: 'password',
                component:password,
                props: true,
            },


            { path: '/hostdetail/:index', component: Hostdetailview, name: 'hotelViewData' },
            { path: '/hotelEdit/:index', component: HotelEdit, name: 'hotelEdit'},
            { path: '/bookedhotel', component: Hotebooking, name: 'hotelbooking'},

       
        ]

    },
    
   

    {
        path:'/admindashboard',
        name:'AdminDashboard',
        beforeEnter: isAdmin,
        component:AdminDashboard,
   
        children:[
            {
                path:'/addinghotel',
                name:'addinghotel',
                component:Addinghotel,
            },
            {
                path:'/contactusUsers',
                name:'contactusUsers',
                component:ContactusUsers,
            },
            {
                path:'/manageHotelPayment',
                name:'manageHotelPayment',
                component:manageHotelPayment,
            },
            
            {
                  path:'/managerDetail',
                  name:'managerinfo',
                 component:ManagerInfo,
            },

            {
                path:'/homepage',
                name:'homepage',
                component:Adminhomepage,
            },
            { path: '/hostdetail/:index', component: Hostdetailview, name: 'hotelViewData' },
            { path: '/hotelEdit/:index', component: HotelEdit, name: 'hotelEdit'},
            { path: '/bookedhotel', component: Hotebooking, name: 'hotelbooking'},

       
        ]



        
    },
  
    

    {
        path:'/policy',
        name:'Policy',
        component:securityPolicy,
    },

   
    {
        path: '/login',
        name: 'login',
        beforeEnter: isUser,
        meta: {auth:true},
        component:Login
    },
    {
        path: '/terms',
        name: 'terms',
        component:Terms
    },

    {
        path: '/searchingInvoice',
        name: 'Invice',
        component:InvoiceCheck
    },
    
    {
        path: "/search-results",
        name: "search-results",
        component: SearchResults,
      },

      {
        path: '/booking/:hotelId',
        name: 'Booking',
        component: Booking,
        beforeEnter: (to, from, next) => {
            const hotelId = to.params.hotelId;
            store.dispatch('fetchHotelData', hotelId)
              .then(() => next())
              .catch(error => next(error));
        }
      },
  
    {
        path: '/HotelProfile/:id',
        name:'hotelProfile',
        component :HotelProfile,
        props:true
    }, 
    { path: '/:pathMatch(.*)*', component: NotFound},
]

const router = createRouter({
    history:createWebHistory(process.env.BASE_URL),
    routes
});






export default router

