<template>
<Nav></Nav>
 <div class="flex flex-col md:flex-row h-screen items-center mt-8">
    <div class="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
      <img src="../../public/login.jpg" alt="Login Background" class="w-full h-full object-cover">
    </div>
    <div class="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
      <div class="w-full" v-if="!loading">
        <h1 class="text-2xl font-bold text-black-600 leading-tight mt-12">Log in to your account</h1>
  
        <form class="mt-6" @submit.prevent="submitForm">
          <div class="mb-4">
            <label for="email" class="block text-gray-700 flex items-center">
              <i class="fas fa-user text-black-500 mr-2"></i>
              Email Address
            </label>
            <input v-model="email" type="email" id="email" placeholder="Enter Email Address" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" autofocus autocomplete required>
            <div v-if="error" class="text-yellow-500 mt-2">{{ error }}</div>
          </div>
  
          <div class="mb-4">
            <label for="password" class="block text-gray-700 flex items-center">
              <i class="fas fa-lock text-black-500 mr-2"></i>
              Password
            </label>
            <input v-model="password" type="password" id="password" placeholder="Enter Password" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" required>
          </div>
  
          <button type="submit" class="w-full bg-yellow-600 hover:bg-black-400 focus:bg-black-400 text-white font-semibold rounded-lg px-4 py-3 mt-6">Log In</button>
        </form>
  
        <hr class="my-6 border-gray-300 w-full">
      </div>
  
      <div v-else>
        <!-- Custom loading animation (replace with your preferred animation) -->
        <div class="flex items-center justify-center h-screen">
          <div class="custom-loader"></div>
        </div>
      </div>
    </div>
  </div>

<Footer></Footer>
</template>

<script>
import Nav from "./Navbar-component.vue";
import Footer from "./Footer-Component.vue";
export default {
  components: {
   Nav,
   Footer,
  },
  data() {
    return {
      email: '',
      password: '',
      error: '',
      loading: false,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      const credentials = {
        email: this.email,
        password: this.password,
      };

      this.$store.dispatch('login', credentials)
        .then(() => {
          const userRole = this.$store.state.role;
          const token = this.$store.state.token;
          console.log('User Role:', userRole);
          console.log('User Role:', token);
          if (userRole === 'admin') {
            var item = "homepage";
            this.$router.push({ path: `/${item}` });
          } else if (userRole === 'manager') {
            var manager = "pageformanager";
            this.$router.push({ path: `/${manager}` });
          } else {
            this.$router.push('/dashboard');
          }
          this.loading = false; // Set loading state back to false after successful login
        })
        .catch((error) => {
          console.error('Login failed:', error);
          this.error = 'Invalid credentials. Please check your email and password.';
          this.loading = false; // Set loading state back to false after login error
        });
    },
  },
};
</script>

<style>
/* Define your custom loader animation using CSS */
.custom-loader {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
