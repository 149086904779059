<template>
     <div class="cont"></div>
     <div v-if="isLoading" class="preloader">
      <!-- You can customize the preloader here -->
      <div class="loader"></div>
    </div>
<div class="container bg-white max-w-max mb-10 rounded-sm shadow-lg mx-auto text-sm p-8">
     <h2 class="text-center  text-lg font-semibold mb-6">Payment Steps</h2>

    <div class="steps mt-8 space-y-4 ">
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-users text-white"></i>
            </div>
            <p class="text-lg">Select the Guest, Lodge, or Hotel</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-book text-white"></i>
            </div>
            <p class="text-lg">Select the Booking Details, including the number of adults, children, and infants</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="far fa-calendar-alt text-white"></i>
            </div>
            <p class="text-lg">Select the check-in date and checkout date</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-user text-white"></i>
            </div>
            <p class="text-lg">Enter your credentials</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-phone text-white"></i>
            </div>
            <p class="text-lg">Enter your phone number for payment</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-mobile-alt text-white"></i>
            </div>
            <p class="text-lg">Check your phone for a confirmation code</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="fas fa-lock text-white"></i>
            </div>
            <p class="text-lg">Enter your password for payment</p>
        </div>
        <div class="flex items-center space-x-2">
            <div class="w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center">
                <i class="far fa-file-alt text-white"></i>
            </div>
            <p class="text-lg">If successful, download the invoice and verify the reference number</p>
        </div>
    </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: true, // Initially, set the loading state to true
    };
  },
  mounted() {
    // Simulate an API call or loading process
    setTimeout(() => {
      this.isLoading = false; // Set loading state to false when the page is ready
    }, 2000); // Adjust the timeout value as needed
  },
};
</script>






<style>
.cont 
{
     height: 80px;
}
.cont {
  height: 80px;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>