<template>
    <Navbar></Navbar>
     <Contactus></Contactus>
    <Footer></Footer>
</template>

<script>
import Navbar from '../components/Navbar-component.vue';
import Footer from '../components/Footer-Component.vue';
import Contactus from '../components/contactus-componet.vue';
export default 
{ 
    components: {
        Navbar,
        Contactus,
        Footer,

    },

}

</script>