<template>
  <div id="map" class="container mx-auto rounded-lg"></div>
  <div class="space"></div>
</template>

<script>
import L from "leaflet";

export default {
  props: {
    hotelData: {
      type: Array,
    },
  },
  mounted() {
    this.initMap(this.hotelData);
  },
  methods: {
    initMap(data) {
      const latitude = data.latitude;
      const longitude = data.longitude;
      const image = data.HmainIMagePath;
      const price = data.price;
      const address = data.hAdress2;
      const address2 = data.hcity;
      const map = L.map("map").setView([latitude, longitude], 13);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "&copy; OpenStreetMap contributors",
      }).addTo(map);

      L.marker([latitude, longitude])
        .addTo(map)
        .bindPopup(
          `
    <div class="bg-white rounded-lg shadow-lg p-4">
  <img src="${image}" alt="Hotel Image" class="w-full h-auto rounded-t-lg">
  <div class="p-4">
    <h3 class="text-xl font-semibold mb-2">Hotel Name</h3>
    <p class="text-gray-600 mb-2">Location: ${address}, ${address2}</p>
    <p class="text-green-500 text-lg font-semibold">Price: <span class="price">${price}</span>/night</p>
  </div>
</div>
    `
        )
        .openPopup();
    },
  },
};
</script>

<style>
#map {
  z-index: 0;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.hotel-card {
  width: 200px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.hotel-card img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.hotel-card h3 {
  margin: 0;
  font-size: 18px;
}

.hotel-card p {
  margin: 5px 0;
  font-size: 14px;
}

.hotel-card .price {
  font-weight: bold;
  color: #f00;
}
.space {
  height: 70px;
}
</style>