<template>
  
  <div class="bg-white rounded-lg shadow-lg px-8 py-10 max-w-xl mx-auto">
  
    <div class="flex items-center justify-between mb-8">
        <div class="flex items-center">
            <img class="h-8 w-8 mr-2" src="../../public/logo.png"
                alt="Logo" />
            <div class="text-gray-700 font-semibold text-lg">Dormi.co.tz</div>
        </div>
        <div class="text-gray-700">
            <div class="font-bold text-xl mb-2">INVOICE</div>
            <div class="text-sm">
              
            </div>
            <div class="text-sm">
                <div>
                    Invoice #: {{ bookingDetail.payment ? bookingDetail.payment.reference_number : 'N/A' }}<br />
                </div>
            </div>
        </div>
    </div>
    <div class="border-b-2 border-gray-300 pb-8 mb-8" v-if="userdataresponse">
        <div v-for="info in userdataresponse.userinformation" :key="info.key" class="bg-white shadow-lg rounded-lg p-4 mb-4 text-left">
  <h2 class="text-2xl font-bold mb-4">Booking Infomation</h2>
  <div class="text-gray-700 mb-2">
    <i class="fas fa-user mr-2"></i> {{ info.gFirstName }} {{ info.gLastName }}
  </div>
  <div class="text-gray-700 mb-2">
    <i class="fas fa-map-marker-alt mr-2"></i> {{ info.gCity }}, {{ info.gState }}, {{ info.gCountry }}
  </div>
  <div class="text-gray-700">
    <i class="fas fa-envelope mr-2"></i> {{ info.gEmail }}
  </div>
</div>

       
        <div class="bg-gray-100 p-4 rounded-lg shadow-md mt-5 flex items-center">
   
    <div class="bg-gray-100 p-4 rounded-lg shadow-md mt-5 flex items-center">
    <i class="fas fa-check-circle text-green-500 text-2xl mr-2"></i> <!-- Add the Font Awesome icon here -->
    <div class="text-gray-700 font-semibold">Paid</div>
  </div>

</div>
    </div>
    <table class="w-full text-left mb-8">
        <thead v-if="userdataresponse">
            <tr v-for="hotel in userdataresponse.hotel" :key="hotel.id">
                <th class="text-gray-700 font-bold uppercase py-2">{{ hotel.htype }} Name</th>
                <th class="text-gray-700 font-bold uppercase py-2">Days</th>
                <th class="text-gray-700 font-bold uppercase py-2">price</th>
            </tr>
        </thead>
        <tbody  v-if="userdataresponse">

            <!-- $userinformation = [
            'userinformation' => $userid,
            'booking'         => $booking,
        ];
        return response()->json($userinformation); -->
            
          
            <tr v-for="hotel in userdataresponse.hotel" :key="hotel.id">
                <td class="py-4 text-gray-700">{{ hotel.name }}</td>
                <td class="py-4 text-gray-700">{{bookingDetail.days }}</td>
                <td class="py-4 text-gray-700">{{ (bookingDetail.totalprice/bookingDetail.days).toLocaleString() }}</td>
                <td class="py-4 text-gray-700">{{ bookingDetail.totalprice.toLocaleString() }}</td>
            </tr>
        </tbody>
    </table>
  

    <div class="flex justify-end mb-8" v-for="hotel in userdataresponse.hotel" :key="hotel.id">
        <div class="text-gray-700 mr-2">Total:</div>
        {{ bookingDetail.totalprice.toLocaleString()}}
       
    </div>


    <div v-if="userdataresponse.booking">
  <div v-for="booking in userdataresponse.booking" :key="booking.id">
    <div class="flex justify-between mb-4 p-4 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
      <div class="text-gray-700">
        <i class="fas fa-calendar-check mr-2"></i> Check In
      </div>
      <div class="text-gray-700">{{ booking.bDateFrom }}</div>
    </div>
    <div class="flex justify-between mb-4 p-4 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
      <div class="text-gray-700">
        <i class="fas fa-calendar-times mr-2"></i> Check Out
      </div>
      <div class="text-gray-700">{{ booking.bDateTo }}</div>
    </div>
  </div>
</div>


    <button @click="printInvoice" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
    Print Invoice
  </button>
 
</div>

  <!-- Add a print button -->
 
</template>


<script >
import axios from 'axios';
export default 
{
    data() {
        return {

            bookingDetail: {
                type: Array, // You can specify the expected data type
                required: true // Required
            },
            userid:'',
            userdataresponse: {},
        }
    },

    mounted() {
        this.bookingDetail = this.$store.state.bookingdata;
      
        //bookingDetail.payment ? bookingDetail.payment.reference_number : 'N/A'
      
        this.userid = this.bookingDetail.payment??this.bookingDetail.payment;
        const username = this.userid.guest_id;
        this.userid = username;
        this.userdetail();
       
    },
    methods: {
        async userdetail() {
            const FormData = {
                userid: this.userid,
            }
            const searchuser = await axios.post('http://127.0.0.1:8000/api/userinformation', FormData);
           console.log(searchuser.data);
            this.userdataresponse = searchuser.data;
           
        
          
        },

        printInvoice() {
      // Hide the print button before printing
      const printButton = document.querySelector('.bg-blue-500');
      if (printButton) {
        printButton.style.display = 'none';
      }

      // Use window.print() to trigger the browser's print dialog
      window.print();

      // Show the print button again after printing
      if (printButton) {
        printButton.style.display = 'block';
      }
    },
    }
              
}

</script>


<style scoped>
.bg-black {
 background-color: #1e2028;
}
.bg-white {
 background-color: #fafbf6;
}

</style>