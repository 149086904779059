<template>
  <div
    class="container d-flex px-6 pb-3 space-y-4 bg-white rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 mx-4"
  >
    <div>
      <div v-if="showmessagegood">
        <div role="alert" v-if="messagegood != ''">
          <div class="text-white font-bold rounded-t px-4 py-2 bg-primary">
            Success
          </div>
          <div
            class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700"
          >
            <p>Update secussefully</p>
          </div>
        </div>
      </div>

      <div v-if="showmessagebad">
        <div role="alert" v-if="messagegood != ''">
          <div
            class="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-2"
          >
            <svg
              class="w-5 h-5 mr-2 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 2C5.03 2 1 6.03 1 11s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm1 13h-2v-2h2v2zm0-4h-2V5h2v6z"
              />
            </svg>
            Password is wrong!!!
          </div>
          <div
            class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700"
          >
            <p>Update successfully</p>
          </div>
        </div>
      </div>

      <h2 class="text-2xl font-semibold text-gray-800">Password Change</h2>
      <div class="mb-4">
        <label for="currentPassword" class="block text-gray-600"
          >Current Password</label
        >
        <input
          type="password"
          v-model="currentPassword"
          id="currentPassword"
          name="currentPassword"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div class="mb-4">
        <label for="newPassword" class="block text-gray-600"
          >New Password</label
        >
        <input
          type="password"
          v-model="newPassword"
          id="newPassword"
          name="newPassword"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div class="mb-6">
        <label for="confirmPassword" class="block text-gray-600"
          >Confirm New Password</label
        >
        <input
          type="password"
          v-model="confirmPassword"
          id="confirmPassword"
          name="confirmPassword"
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div class="text-center">
        <button
          @click="submitForm"
          class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg focus:outline-none transition-colors duration-300"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>


  <div class="flex justify-center mt-10 w-full bg-slate-400 p-5 mx-2 shadow-lg rounded-sm">
    <div class="grid bg-slate-50 shadow-sm p-6 rounded-lg text-center transition-transform transform hover:scale-105">
      <input
        type="file"
        ref="fileInput"
        id="profile-picture-upload"
        style="display: none;"
        @change="handleFileUpload"
      />
      <label
        for="profile-picture-upload"
        @click="openFileInput"
        class="cursor-pointer text-blue-600 hover:underline custom-font"
      >
        Upload Profile Picture
      </label>
      <div v-if="imagePreview" class="mt-4">
        <img
          :src="imagePreview"
          alt="Profile Preview"
          class="max-w-full h-auto mx-auto"
        />
      </div>
      <div v-if="selectedFile != null" class="p-3">
        <button
          class="bg-blue-500 hover:bg-blue-700 transform transition-transform duration-300 hover:scale-105 text-white font-bold py-2 px-4 rounded shadow-md flex items-center"
          @click="uploadProfile"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
          Upload Profile
        </button>
      </div>
    </div>
  </div>

</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showmessagebad: false,
      manager: this.$store.state.manager,
      messagegood: "",
      showmessagegood: false,
      selectedFile: null,
      imagePreview: null,
      messagebad: "",
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        this.imagePreview = URL.createObjectURL(file);
      }
    },

   async uploadProfile() {
      const formData = {
        user_id: this.manager.user_id,
        profile_picture: this.selectedFile,
      }
      const response = await axios.post('https://api.dormi.co.tz/api/profilepicture', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if(response.data == "ok"){
            alert("Profile picture updated"); 
          } 
          else{
            alert("Profile picture not updated"); 
          }

         
    },

    
    async submitForm() {
      const form = {
        user_id: this.manager.user_id,
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };

      const response = await axios.post(
        "https://api.dormi.co.tz/api/updatepassword",
        form
      );
      console.log(response.data); //notmatch
      if (response.data == "notmatch") {
        this.messagebad = "Password not match";
        this.showmessagebad = true;
        this.showmessagegood = false;
        alert("Password not match");
      } else if (response.data == "ok") {
        this.messagegood = "Password updated";
        this.showmessagegood = true;
        this.showmessagebad = false;
        alert("Password updated");
      }

      if (response.data == "oldpassword") {
        this.messagebad = "Old password is wrong";
        this.showmessagebad = true;
        this.showmessagegood = false;
        alert("Old password is wrong");
      }
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.imagePreview = null;
      }
    },
  },

  mounted() {
    const store = this.$store.state.manager;
    console.log("this is the store");
    console.log(store);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=YourFontFamily");

/* Apply the custom font to the label */
.custom-font {
  font-family: "YourFontFamily", sans-serif;
}


</style>