<template>
 
  <div class="container max-auto mx-auto rounded-sm mt-20 mb-20 px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col sm:flex-row gap-3 items-start">
      <div class="sm:w-full md:w-1/2 sm:mx-auto">
        <p class="text-center font-semibold mb-8">Room in a home hosted by Dormi</p>
        <div class="mt-1 flex flex-col sm:flex-row gap-2 sm:justify-start">
          <div class="border-1 w-full sm:w-45 h-20 p-8">
            <div class="flex flex-row gap-2 items-center">
              <i class="fa fa-shower" aria-hidden="true"></i>
              <p class="text-sm">shower</p>
            </div>
          </div>
          <div class="border-1 w-full sm:w-45 h-20 p-8">
            <div class="flex flex-row gap-2 items-center">
              <i class="fa fa-bed" aria-hidden="true"></i>
              <p class="text-sm">single bed</p>
            </div>
          </div>
          <div class="border-1 w-full sm:w-45 h-20 p-8">
            <div class="flex flex-row gap-2 items-center">
              <i class="fa fa-wifi" aria-hidden="true"></i>
              <p class="text-sm">free wifi</p>
            </div>
          </div>
        </div>
        <hr class="mt-5 mb-8">
        <div class="roominfo flex flex-col gap-2 pb-5 sm:w-full md:w-4/5">
          <div class="flex flex-row md:flex-row sm:flex-col gap-5">
            <div>
              <i class="fa fa-bed" aria-hidden="true"></i>
            </div>
            <div class="flex flex-col" v-if="hotelData">
              <div class="capital text-start">Room in a {{ hotelData.hAdress2 }} </div>
              <div>Your own room in a home, plus access to shared spaces.</div>
            </div>
          </div>
          <div class="flex flex-row gap-5">
            <div>
              <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>
            </div>
          
            <div class="flex flex-col">
              <div class="capital text-start">Great communication</div>
              <div>90% of recent guests rated Carmela 5-star in communication.</div>
            </div>
          </div>
          <div class="flex flex-row gap-5">
            <div>
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </div>
            <div class="flex flex-col">
              <div> {{ expaire }}.</div>
            </div>
          </div>
        </div>
      </div>
        <Bookingcard :hotelData="hotelData" />
    </div>
  </div>
</template>

<script>
import Bookingcard from "./Booking-card.vue";

export default {
  components: {
    Bookingcard,
  },
  props: {
    hotelData: {
      type: Object, // Adjust the type according to your data structure
      required: true,
    },
    expaire:{
      type:String
    }
}
}
</script>

<style>
.border-1 {
  border: 1px solid rgb(224, 212, 212);
  border-radius: 3px;
}

.capital {
  font-size: 15px;
  color: black;
  font-weight: bold;
}

@media (max-width: 640px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gap-5 {
    gap: 0.5rem;
  }

  .items-start {
    align-items: flex-start;
  }

  .text-sm {
    font-size: 12px;
  }
}
</style>
